import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const QAItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-gray-300">
      <button
        className={`w-full text-left p-4 flex justify-between items-center bg-white ${isOpen ? ' bg-gray-800' : 'text-gray-800'}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className={`transition-colors duration-300 hover:text-white`}>{question}</span>
        {isOpen ? <FaChevronUp className={`text-gray-800 hover:text-white`} /> : <FaChevronDown className={`text-gray-800 ${isOpen ? 'text-white' : 'hover:text-white'}`} />}
      </button>
      {isOpen && (
        <div className="p-4 bg-gray-100">
          <p className="text-gray-700">{answer}</p>
        </div>
      )}
    </div>
  );
};

const QASection = () => {
  const qaList = [
    {
      question: 'Will I get 90% of my course fee back?',
      answer: `To qualify for the refund, you must complete 90% of the course content within 90 days from the date of purchase.
      The content you have to cover in each section of the course is as follows:
      - Chapter & Practice: 90% including Videos, Articles, Problems and Quiz
      - Contest: You need to solve at least 90% of the Contests
      - Live Class: 90% attendance in each class throughout the complete Course
      - Secure the Course certificate by achieving the cut-off Scores`,
    },
    {
      question: 'Is there any number to contact for queries?',
      answer: 'You can contact our support team at 123-456-7890 for any queries.',
    },
    {
      question: 'How can I enroll for this program?',
      answer: 'You can enroll for the program by visiting our website and clicking on the enroll button.',
    },
    {
      question: 'What if I am not selected?',
      answer: 'If you are not selected, you can apply again for the next batch.',
    },
  ];

  return (
    <div className="max-w-6xl mx-auto p-4 bg-gray-50 rounded-lg shadow-lg my-10">
      <h1 className="text-2xl font-bold p-6">Q & A</h1>
      {qaList.map((qa, index) => (
        <QAItem key={index} question={qa.question} answer={qa.answer} />
      ))}
    </div>
  );
};

export default QASection;
