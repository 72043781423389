import React from 'react'
import "../styles/index.css"

const Pagination = () => {
  return (
    <div>
        <div className="pagination mt-10">
        <a href="#">&laquo;</a>
        <a href="#" className="active">1</a>
        <a href="#">2</a>
        <a href="#">3</a>
        <a href="#">4</a>
        <a href="#">5</a>
        <a href="#">6</a>
        <a href="#">&raquo;</a>
      </div>
    </div>
  )
}

export default Pagination
