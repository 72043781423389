import React, { useState } from 'react';
import cococola from '../assets/icon/cocacola.svg';
import microsoft from '../assets/icon/microsoft.svg';
import ebay from '../assets/icon/ebay.svg';
import amazon from '../assets/icon/amazon.svg';
import ups from '../assets/icon/ups.svg';

const recruiters = [
  { name: 'Coca Cola', logo: cococola },
  { name: 'Microsoft', logo: microsoft },
  { name: 'eBay', logo: ebay },
  { name: 'Amazon', logo: amazon },
  { name: 'UPS', logo: ups },
  { name: 'Coca Cola', logo: cococola },
  { name: 'Microsoft', logo: microsoft },
  { name: 'eBay', logo: ebay },
  { name: 'Amazon', logo: amazon },
  { name: 'Amazon', logo: amazon },
  { name: 'Amazon', logo: amazon },
  { name: 'UPS', logo: ups },
];

const Recruiters = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  return (
    <div className="flex items-center justify-center mr-8">
      <div className="text-center py-10 w-[1150px]">
        <h2 className="text-red-500 text-sm mb-4">COMPANYS</h2>
        <h1 className="text-3xl font-bold mb-8">Our Top Recruiters</h1>
        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-6 gap-10 px-10">
          {recruiters.map((recruiter, index) => (
            <div 
              key={index} 
              className="bg-gray-100 p-4 rounded-lg flex items-center justify-center w-[135px] h-24 group relative overflow-hidden"
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
            >
              <img 
                src={recruiter.logo} 
                alt={recruiter.name} 
                className={`h-20 transition-transform duration-500 ${hoveredIndex === index ? 'animate-bounce-up-down' : ''}`}
              />
            </div>
          ))}
        </div>

        <style jsx>{`
          @keyframes bounce-up-down {
            0% { transform: translateY(0); }
            50% { transform: translateY(50%); }
            100% { transform: translateY(0); }
          }

          .animate-bounce-up-down {
            animation: bounce-up-down 1s ease-in-out;
          }
        `}</style>
      </div>
    </div>
  );
};

export default Recruiters;
