import React from 'react'

const InfoBox = () => {
  return (
    <div class="info-container">
    <div class="info-box">
      <i class="fas fa-code icon learn-coding" title="Learn Coding"></i>
      <h2>Learn Coding</h2>
      <p>Learn coding online from anywhere in the world.</p>
    </div>
    <div class="info-box">
      <i class="fas fa-users icon online-community" title="Online Community"></i>
      <h2>Online Community</h2>
      <p>Share and get real time advice from industry professionals.</p>
    </div>
    <div class="info-box">
      <i class="fas fa-briefcase icon jobs" title="Jobs"></i>
      <h2>Jobs</h2>
      <p>Get access to jobs and projects from around the world.</p>
    </div>
    <div class="info-box">
      <i class="fas fa-newspaper icon trending-news" title="Trending News"></i>
      <h2>Trending News</h2>
      <p>Get trending news and information about the tech community.</p>
    </div>
  </div>
  )
}

export default InfoBox