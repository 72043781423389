import React from 'react';
import aboutUsImage from '../../assets/image_2024_08_14T15_43_39_513Z.png'; // Assume you have moved your image to src/assets folder

const AboutUs = () => {
  return (
    
   <>
   <div className=' font-bold text-3xl text-center m-4'>About Us</div>
   <p className="text-lg md:text-l m-8 text-center">
              At Coding Community, our mission is simple: to make coding accessible and enjoyable for everyone.
              Wh munithe ever-evolving tech landscape.
            </p>
    <div className="bg-[#FDEAE6] text-gray-900">
      {/* Upper Section with Single Image with Reduced Height */}
      <div className="relative">
        <img 
          src={aboutUsImage} 
          alt="Coding Community" 
          className="w-full h-64 md:h-96 object-cover"  // Adjusted height
        />
      </div>
      
      {/* Mission Section */}
      <div className="bg-[#F5F6F9] py-12 px-6 md:px-16">
        <h2 className="text-3xl md:text-4xl font-semibold text-center mb-6">Our Mission</h2>
        <div className="flex flex-col md:flex-row items-center ">
          <div className="md:w-1/2">
            <p className="text-lg md:text-xl text-center mb-4">
              At Coding Community, our mission is simple: to make coding accessible and enjoyable for everyone.
              Whether you're a complete beginner or a seasoned developer, we provide the resources, support,
              and community you need to excel in the ever-evolving tech landscape.
            </p>
            <p className="text-lg md:text-xl text-center">
              We believe that learning to code should be both accessible and enjoyable, and we're committed to empowering you with the tools,
              knowledge, and support you need to turn your tech aspirations into reality.
            </p>
          </div>
          <div className="md:w-1/3 flex justify-center mb-8 md:mb-0 md:ml-8">
            <img 
              src="https://via.placeholder.com/400" 
              alt="Team Collaboration" 
              className=" h-auto object-cover rounded-lg shadow-md"
            />
          </div>
        </div>
      </div>
    </div></>
  );
};

export default AboutUs;
