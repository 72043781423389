import React from 'react';
import '../styles/index.css';

const NewsCategory = () => {
  return (
    <div className="flex justify-center">
      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-6 shadow-md gap-6 p-4 mt-20 mb-20 bg-white rounded-md max-w-full">
        <div className="p-2 card1 text-white font-semibold shadow-md rounded-md flex justify-center items-center w-40 h-24">
          <span className="truncate text-center">Live Course</span>
        </div>
        <div className="card2 p-2 text-white font-semibold shadow-md rounded-md flex justify-center items-center w-40 h-24">
          <span className="truncate text-center">Fundamental Course</span>
        </div>
        <div className="card3 p-2 text-white font-semibold shadow-md rounded-md flex justify-center items-center w-40 h-24">
          <span className="truncate text-center">DSA/Placement</span>
        </div>
        <div className="card4 p-2 text-white font-semibold shadow-md rounded-md flex justify-center items-center w-40 h-24">
          <span className="truncate text-center">Web Development</span>
        </div>
        <div className="card5 p-2 text-white font-semibold shadow-md rounded-md flex justify-center items-center w-40 h-24">
          <span className="truncate text-center">Machine Learning</span>
        </div>
        <div className="card6 p-2 text-white font-semibold shadow-md rounded-md flex justify-center items-center w-40 h-24">
          <span className="truncate text-center">Data Science</span>
        </div>
      </div>
    </div>
  );
};

export default NewsCategory;
