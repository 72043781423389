import React from 'react';

const SubscriptionBanner = () => {
  return (
    <div className=" inset-0 flex items-center justify-center z-50 p-4">
      <div className="bg-gradient-to-r from-orange-400 to-orange-300 p-8 md:p-12 max-w-screen-2xl w-full h-auto relative rounded-lg">
        <h2 className="text-3xl md:text-4xl font-extrabold mb-6 text-white text-center">
          Subscribe And Get 20% OFF On Your First Online Course
        </h2>
        <div className="flex flex-col md:flex-row items-center justify-center mb-6 space-y-4 md:space-y-0 md:space-x-4 p-5">
          <input
            type="email"
            placeholder="Write Your Email Address*"
            className="p-3 rounded-full w-full md:w-80 border border-gray-300 focus:outline-none"
          />
          <button className="bg-white text-orange-600 px-6 py-3 mb mb-8 rounded-md font-bold hover:bg-orange-400 focus:outline-none">
            Submit
          </button>
        </div>
        <p className="text-sm text-gray-200 text-center">
          Sign Up for our newsletter and never miss any offers
        </p>
      </div>
    </div>
  );
};

export default SubscriptionBanner;
