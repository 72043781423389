import React, { useState } from 'react';
import '../styles/index.css';
import login from '../assets/login.png';
import FacebookIcon from '../assets/icon/facebook.svg';
import LinkedinIcon from '../assets/icon/linkedin.svg';
import TwitterIcon from '../assets/icon/twitter.svg';
import Googleicon from '../assets/icon/google.svg'

const LoginModal = ({ show, onClose }) => {
  const [isRegistering, setIsRegistering] = useState(false);

  const handleRegisterClick = (e) => {
    e.preventDefault();
    setIsRegistering(true);
  };

  const handleSignInClick = (e) => {
    e.preventDefault();
    setIsRegistering(false);
  };

  if (!show) {
    return null;
  }

  return (
    <div className={`modal-login ${show ? 'show' : ''}`}>
      <div className="login-container">
        <span className="close" onClick={onClose}>&times;</span>
        <div className="lefts">
          <div className="boxs">
            {!isRegistering ? (
              <>
                <h2>Sign in</h2>
                <p>Welcome, enter your details</p>
                <form className="login-form">
                  <label htmlFor="email">Email Address</label>
                  <input type="email" id="email" name="email" placeholder="John@gmail.com" />

                  <label htmlFor="password">Password</label>
                  <input type="password" id="password" name="password" placeholder="Enter 6 characters or more" />

                  <div className="remember-forgots">
                    <label>
                      <input type="checkbox" name="remember" /> Remember me
                    </label>
                    <a href="#">Forgot password?</a>
                  </div>

                  <button type="submit">Sign In</button>
                </form>
                <div className="ors">
                  <hr />
                  <p>or</p>
                  <hr />
                </div>
                <div className="social-logins">
                <a href="#" className="text-orange-500 hover:underline">
                      <img width="48" height="48" src={Googleicon} alt="linkedin" />
                    </a>                    <a href="#" className="text-orange-500 hover:underline">
                      <img width="48" height="48" src={LinkedinIcon} alt="linkedin" />
                    </a>
                    <a href="#" className="text-orange-500 hover:underline">
                      <img width="48" height="48" src={TwitterIcon} alt="twitter" />
                    </a>                 
                     <a href="#" className="text-orange-500 hover:underline"> <img width="48" height="48" src={FacebookIcon} alt="facebook" />
                    </a>
                </div>
                <p className="registers">Don't have an account? <a href="#" onClick={handleRegisterClick}>Register</a></p>
              </>
            ) : (
              <>
                <div className="registerContainer">
                  <h2>Register</h2>
                  <p>Welcome, enter your details</p>
                  <form className="login-form">
                    <label htmlFor="name">Full Name</label>
                    <input type="text" id="name" name="name" placeholder="John Doe" />

                    <label htmlFor="email">Email Address</label>
                    <input type="email" id="email" name="email" placeholder="John@gmail.com" />

                    <label htmlFor="password">Password</label>
                    <input type="password" id="password" name="password" placeholder="Enter 6 characters or more" />

                    <label htmlFor="confirm-password">Confirm Password</label>
                    <input type="password" id="confirm-password" name="confirm-password" placeholder=".........." />

                    <button type="submit" className='register-button'>Register</button>
                  </form>
                  <div className="ors">
                    <hr />
                    <p>or</p>
                    <hr />
                  </div>
                  <div className="social-logins register-icons">
                    <a href="#" className="text-orange-500 hover:underline">
                      <img width="40" height="40" src={Googleicon} alt="linkedin" />
                    </a>                    <a href="#" className="text-orange-500 hover:underline">
                      <img width="40" height="40" src={LinkedinIcon} alt="linkedin" />
                    </a>
                    <a href="#" className="text-orange-500 hover:underline">
                      <img width="40" height="40" src={TwitterIcon} alt="twitter" />
                    </a>                 
                     <a href="#" className="text-orange-500 hover:underline"> <img width="40" height="40" src={FacebookIcon} alt="facebook" />
                    </a>
                  </div>
                  <p className="registers">Already have an account? <a href="#" onClick={handleSignInClick}>Sign In</a></p>
                </div>

              </>
            )}
          </div>
        </div>
        <div className="rights">
          <img src={login} alt="Illustration" />
        </div>
      </div>
    </div>
  );
};

export default LoginModal;
