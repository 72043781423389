// CoursesContainer.js
import React from 'react';
import CourseCardOne from './CourseCardthree';
import imageHtml from '../../assets/html.jpeg';
import imagePhp from '../../assets/php.jpeg';
import imageNode from '../../assets/node.jpeg';
import imageJs from '../../assets/js.jpeg';

const CoursesContainerthree = () => {
  const courses = [
    {
      imageSrc: imageHtml,
      title: 'Complete HTML course - Beginners to advanced',
      description: '40K+ interested',
      rating: '4.5',
      link: '#'
    },
    {
      imageSrc: imagePhp,
      title: 'Complete PHP course - Beginners to advanced',
      description: '40K+ interested',
      rating: '4.5',
      link: '#'
    },
    {
      imageSrc: imageNode,
      title: 'Complete Node.js course - Beginners to advanced',
      description: '40K+ interested',
      rating: '4.5',
      link: '#'
    },
    {
      imageSrc: imageJs,
      title: 'Complete JavaScript course - Beginners to advanced',
      description: '40K+ interested',
      rating: '4.5',
      link: '#'
    },
  
  ];

  return (
    <div className="courses-container">
      <div className="card-header">
        <h2 className="courses-heading large lg:ml-10 font-bold">Fundamental</h2>
        <div className="upper">
          <a className="view lg:mr-10" href="#">View all</a>
        </div>
      </div>
      <div className="card-container">
        {courses.map((course, index) => (
          <CourseCardOne
            key={index}
            imageSrc={course.imageSrc}
            title={course.title}
            description={course.description}
            rating={course.rating}
            link={course.link}
          />
        ))}
      </div>
    </div>
  );
};

export default CoursesContainerthree;
