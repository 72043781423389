import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/index.css';
import NewsCategory from '../components/NewsCategory';
import Pagination from '../components/Pagination';

// Import images from the assets folder
import image1 from '../assets/code2.jpeg';
import image2 from '../assets/code2.jpeg';
import image3 from '../assets/code3.jpeg';
import image4 from '../assets/code3.jpeg';
import image5 from '../assets/code2.jpeg';
import image6 from '../assets/code1.png';
import image7 from '../assets/code3.jpeg';

const cardData = [
  {
    title: 'AI, IoT, Quantum Security Among Top 10 Emerging Technologies',
    date: 'June 12th',
    readTime: '12 min read',
    author: 'Arthur: John P. Mello',
    description: 'Ten technologies that need to be on every company\'s radar were identified Tuesday in a trends report released by global technology research company Forrester.',
    imageUrl: image1
  },
  {
    title: 'AI, IoT, Quantum Security Among Top 10 Emerging Technologies',
    date: 'June 12th',
    readTime: '12 min read',
    author: 'Arthur: John P. Mello',
    description: 'Ten technologies that need to be on every company\'s radar were identified Tuesday in a trends report released by global technology research company Forrester.',
    imageUrl: image2
  },
  {
    title: 'AI, IoT, Quantum Security Among Top 10 Emerging Technologies',
    date: 'June 12th',
    readTime: '12 min read',
    author: 'Arthur: John P. Mello',
    description: 'Ten technologies that need to be on every company\'s radar were identified Tuesday in a trends report released by global technology research company Forrester.',
    imageUrl: image3
  },
  {
    title: 'AI, IoT, Quantum Security Among Top 10 Emerging Technologies',
    date: 'June 12th',
    readTime: '12 min read',
    author: 'Arthur: John P. Mello',
    description: 'Ten technologies that need to be on every company\'s radar were identified Tuesday in a trends report released by global technology research company Forrester.',
    imageUrl: image4
  },
  {
    title: 'AI, IoT, Quantum Security Among Top 10 Emerging Technologies',
    date: 'June 12th',
    readTime: '12 min read',
    author: 'Arthur: John P. Mello',
    description: 'Ten technologies that need to be on every company\'s radar were identified Tuesday in a trends report released by global technology research company Forrester.',
    imageUrl: image5
  },
  {
    title: 'AI, IoT, Quantum Security Among Top 10 Emerging Technologies',
    date: 'June 12th',
    readTime: '12 min read',
    author: 'Arthur: John P. Mello',
    description: 'Ten technologies that need to be on every company\'s radar were identified Tuesday in a trends report released by global technology research company Forrester.',
    imageUrl: image6
  },
  {
    title: 'AI, IoT, Quantum Security Among Top 10 Emerging Technologies',
    date: 'June 12th',
    readTime: '12 min read',
    author: 'Arthur: John P. Mello',
    description: 'Ten technologies that need to be on every company\'s radar were identified Tuesday in a trends report released by global technology research company Forrester.',
    imageUrl: image7
  }
];
const Card = ({ title, date, readTime, author, description, imageUrl }) => (
  <div className="max-w-[370px] rounded overflow-hidden shadow-lg mt-10  mx-auto">
    <img className="w-full h-40" src={imageUrl} alt="Card image" />
    <div className="px-6 py-4">
      <div className="font-bold mb-2">{title}</div>
      <div className="pt-4 pb-2" style={{ fontSize: '8px' }}>
        <span className="rounded-full py-1 text-sm text-gray-700 mr-2 mb-2">{date}</span>
        <span className="rounded-full py-1 text-sm text-gray-700 mr-2 mb-2">{readTime}</span>
        <span className="rounded-full py-1 text-sm text-gray-700 mr-2 mb-2">{author}</span>
      </div>
      <p className="text-gray-700 text-sm">
        {description}
      </p>
    </div>
  </div>
);
const CardGrid = () => (
  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 content-center  justify-center w-full max-w-7xl mx-auto" style={{ gap: '8px' }}>
    {cardData.map((card, index) => (
      <Card
        key={index}
        title={card.title}
        date={card.date}
        readTime={card.readTime}
        author={card.author}
        description={card.description}
        imageUrl={card.imageUrl}
      />
    ))}
  </div>
);

const Newspage = () => {

  return (
    <div>
      <Helmet>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Course Category</title>
      </Helmet>

      <div className="news-container">
        <div className="bg-orange-200 text-orange-800 px-4 py-2 mt-8 mx-auto w-60">
          Welcome To Our News Blog
        </div>
        <h1 className="text-4xl text-white font-semibold mt-4 text-center mx-auto max-w-4xl px-4">
          Explore our blog to get insights, trending news and stories
        </h1>
        <p className="text-center mt-4 mx-auto text-orange-100 max-w-2xl px-4">
          Dive into the latest trends, news, and in-depth analyses from the ever-evolving world of technology. From cutting-edge innovations to expert opinions, our blog is your go-to source for staying informed and inspired. Whether you're a tech enthusiast, a professional, or just curious about the digital age, we've got you covered.
        </p>
        <div className="flex justify-center items-center mt-8 mx-auto w-full max-w-xl">
          <input
            type="text"
            placeholder="Search"
            className=" px-4 py-2 bg-slate-100 rounded-l-full outline-none w-full"
          />
          <button className="search-button bg-orange-700 mt-[-10px] px-6 py-2 ml-4 text-white">
            Search
          </button>
        </div>
      </div>

<NewsCategory/>
<CardGrid />
<Pagination/>    </div>
  );
};

export default Newspage;
