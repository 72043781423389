import React, { useState, useEffect } from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '../styles/index.css'; // Ensure your CSS is imported here
import logo from '../assets/logo.png';
import ad from '../assets/ad.jpg';
import DiscussionModal from '../modals/DiscussionModal'; // Import the modal
import ImprovementModal from '../modals/ImprovementModal';
import EditIcon from '../assets/icon/edit.svg';
import ThumbsUpIcon from '../assets/icon/like.svg';
import ShareIcon from '../assets/icon/share.svg';
import FlagIcon from '../assets/icon/report.svg';
import comment from '../assets/icon/chat.svg';


const HTMLIntroduction = () => {
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [modalVisible, setModalVisible] = useState(false); // State for modal visibility
    const [improvementModalVisible, setImprovementModalVisible] = useState(false); // State for improvement modal visibility

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (!e.target.closest('.sidebar') && !e.target.closest('.d-hamburger-menu')) {
                setSidebarVisible(false);
            }
            if (!e.target.closest('.d-dropdown')) {
                setDropdownVisible(false);
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const toggleModal = () => {
        setModalVisible(!modalVisible);
    };

    const toggleImprovementModal = () => {
        setImprovementModalVisible(!improvementModalVisible);
    };

    return (
        <div className="deatil-container">
            <div className={`sidebar ${sidebarVisible ? 'show' : ''}`}>
                <div className="d-above">
                    <div className="inside">Solve Coding Problems</div>
                </div>
                <div className="d-above">
                    <div className="inside">Share your experience</div>
                </div>
                <div className="detail-scrollbar">
                    {['HTML Tutorial', 'HTML Introduction', 'HTML Editors',
                        'HTML Basics', 'HTML Comments', 'HTML Elements', 'HTML Attributes',
                        'HTML Headings', 'HTML Paragraphs', 'HTML Introduction', 'HTML Introduction',
                        'HTML Introduction', 'HTML Introduction', 'HTML Text Formatting'].map((item, index) => (
                            <div className="d-scroller" key={index}>{item}</div>
                        ))}
                </div>
            </div>
            <div className="main-content">
                <div className="content">
                    <h1 className='text-4xl font-semibold mb-2'>HTML Introduction</h1>
                    <div className="updated-container mb-4">
                        <p className="updated">Last Updated: 20 May, 2024</p>
                        <div className="d-icons">
                            <i className="fas fa-pencil-alt"></i>
                            <div className={`d-dropdown ${dropdownVisible ? 'show' : ''}`}>
                                <i className="fas fa-ellipsis-v d-dropdown-toggle" onClick={() => setDropdownVisible(!dropdownVisible)}></i>
                                <div className="d-dropdown-menu">
                                    <a href="#" className="flex items-center space-x-2">
                                        <img src={EditIcon} alt="edit" className="w-5 h-5" />
                                        <span>Improve</span>
                                    </a>
                                    <a href="#" className="flex items-center space-x-2">
                                        <img src={ThumbsUpIcon} alt="like" className="w-5 h-5" />
                                        <span>Like</span>
                                    </a>

                                    <a href="#" className="flex items-center space-x-2">
                                        <img src={ShareIcon} alt="share" className="w-5 h-5" />
                                        <span>Share</span>
                                    </a>
                                    <a href="#" className="flex items-center space-x-2">
                                        <img src={FlagIcon} alt="report" className="w-5 h-5" />
                                        <span>Report</span>
                                    </a>
                                </div>

                            </div>
                        </div>
                    </div>
                    <hr className="detail-hr mb-3" />
                    <p className="para">
                        HTML, or <strong>HyperText Markup Language</strong>, is the standard markup language used to create web pages.
                        It’s a combination of Hypertext, which defines the link between web pages, and Markup language,
                        which is used to define the text document within tags to structure web pages. This language is used to annotate
                        text so that machines can understand and manipulate it accordingly. HTML is human-readable and uses tags to
                        define what manipulation has to be done on the text. This guide will help you understand the workings of HTML
                        and explain it with examples.
                    </p>
                    <h2 className='text-2xl font-semibold mt-3'>Table of Content</h2>
                    <ul className="table-content list">
                        {['What is HTML?', 'Features of HTML', 'HTML Elements and Tags', 'HTML Page Structure',
                            'Web Browsers', 'Why learn HTML?', 'Advantages of HTML', 'Disadvantages of HTML'].map((item, index) => (
                                <li key={index} className=" before:content-['•']  before:mr-2"><a className='underline' href="#">{item}</a></li>
                            ))}
                    </ul>
                    <div className="header-content">
                        <img src={logo} alt="Logo" />
                        <span className="d-logo ">CodingCommunity</span>
                        <div className="interaction">
                            <span className="comment w-5 h-5" onClick={toggleModal}>
                                <img src={comment} alt="Comment" />
                            </span>
                            <span className="like w-5 h-5 mr-9  flex">
                                <img src={ThumbsUpIcon} className=' -mt-1' alt="Like" />
                                <span className="like-count   mt-2">308</span>
                            </span>
                            <span className="edit w-5 h-5" onClick={toggleImprovementModal}>
                                <img src={EditIcon} alt="Edit" />
                            </span>
                        </div>
                    </div> 
                    <div className="move">
                        <div className="prev-section">
                            <a href="#" className="prev">
                                <i className="fas fa-chevron-left"></i> Previous Article
                            </a>
                            <a href="#" className="prev-article">Python | Set 2 (Variables, Expressions)</a>
                        </div>
                        <span className="separator"></span>
                        <div className="next-section">
                            <a href="#" className="next">
                                Next Article <i className="fas fa-chevron-right"></i>
                            </a>
                            <a href="#" className="next-article">Type Conversion in Python</a>
                        </div>
                    </div>
                    <div className="similar-reads">
                        <h2>Similar Reads</h2>
                        {[
                            {
                                title: 'Python program to find number of local variables in a function',
                                content: 'Given a Python program, task is to find the number of local variables present in a function. Examples: Input : a = 1 b = 2.1 str = \'GeeksForGeeks\' Output : 3 We can use the co_nlocals() function which...',
                                readTime: '1 min read'
                            },
                            {
                                title: 'Can Global Variables be dangerous?',
                                content: 'In a small code, we can track values of global variables. But if the code size grows, they make code less understandable (hence less maintainable). It becomes difficult to track which function modified the...',
                                readTime: '2 min read'
                            },
                            {
                                title: 'Global keyword in Python',
                                content: 'In this article, we will cover the global keyword, the basic rules for global keywords in Python, the difference between the local, and global variables, and examples of global keywords in Python. What...',
                                readTime: '5 min read'
                            }
                        ].map((article, index) => (
                            <div className="article" key={index}>
                                <h3>{article.title}</h3>
                                <p>{article.content}</p>
                                <div className="meta">
                                    <i className="fas fa-clock"></i>
                                    <span>{article.readTime}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="ad">
                <img src={ad} alt="Ad" />
            </div>
            <div className="d-hamburger-menu" onClick={() => setSidebarVisible(!sidebarVisible)}>
                &#9776;
            </div>
            {modalVisible && <DiscussionModal onClose={toggleModal} />} {/* Discussion modal is called here */}
            {improvementModalVisible && <ImprovementModal onClose={toggleImprovementModal} />} {/* Improvement modal is called here */}
        </div>
    );
};

export default HTMLIntroduction;
