import React from 'react';

function DiscussionPage() {
  const discussions = [
    {
      title: "Can anyone help me debug this JavaScript code?",
      author: "Melissa",
      time: "one week, five hours ago",
      members: 10,
      replies: 6,
    },
    {
      title: "What's the best way to optimize images for a website to improve loading times without...",
      author: "Melissa",
      time: "one week, five hours ago",
      members: 10,
      replies: 6,
    },
    {
      title: "Has anyone worked with both Bootstrap and Tailwind CSS? Which do you prefer for...",
      author: "Melissa",
      time: "one week, five hours ago",
      members: 10,
      replies: 6,
    },
    {
      title: "What are some good resources or tutorials for learning React as a beginner?",
      author: "Melissa",
      time: "one week, five hours ago",
      members: 10,
      replies: 6,
    },
    {
      title: "I'm having trouble with my CSS Flexbox layout. Can someone explain how to center an...",
      author: "Melissa",
      time: "one week, five hours ago",
      members: 10,
      replies: 6,
    },
  ];

  const groups = [
    { name: "Machine Learning", activity: "Active 1 year ago" },
    { name: "Machine Learning", activity: "Active 1 year ago" },
    { name: "Machine Learning", activity: "Active 1 year ago" },
    { name: "Machine Learning", activity: "Active 1 year ago" },
    { name: "Machine Learning", activity: "Active 1 year ago" },
  ];

  return (
    <div className="min-h-screen p-4 mt-10">
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 max-w-7xl mx-auto">
        {/* Left Column */}
        <div className="lg:col-span-2 space-y-4">
          <div className="mb-5">
            <h1 className="text-3xl font-bold">Web Development Group</h1>
          </div>

          <div className="flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-5">
            <div className="flex items-center space-x-2 text-gray-600 hover:text-gray-800 cursor-pointer">
              <span className="material-icons">icon</span>
              <span>Notification</span>
            </div>
            <div className="flex items-center space-x-2 text-gray-600 hover:text-gray-800 cursor-pointer">
              <span className="material-icons">icon</span>
              <span>Messages</span>
            </div>
          </div>
          <div className="relative w-full h-64 rounded overflow-visible">

            <img
              src="https://via.placeholder.com/1500x300" // Dummy image
              alt="Header"
              className="w-full h-full object-cover"
            />
            <div className="absolute flex top-44 z-50 lg:left-20 p-4 items-center ">
              <img
                src="https://via.placeholder.com/100"
                alt="Organizer"
                className="w-32 h-40"
              />
              <div>
                <p className="text-gray-600 ml-10  mt-20 text-lg">Public • Group</p>
                <p className="text-gray-600  ml-10 text-lg" >Organizer:
                  <img src="https://via.placeholder.com/30" alt="Organizer" className="inline w-6 h-6 rounded-full ml-2" />
                </p>
              </div>
            </div>
          </div>

          <div>
            <div className="flex flex-col mt-40 -mb-20">
              <input
                type="text"
                placeholder="Search conversation"
                className="border border-gray-300 rounded-full py-2 px-4 w-full focus:outline-none focus:ring-2 focus:ring-gray-300"
              />
            </div>
            <div className="flex justify-between items-center mt-28 mb-4">
              <h2 className="text-2xl font-semibold">All Discussions</h2>
              <button className="bg-orange-500 text-white lg:px-10 px-2 py-2 rounded">Add Questions</button>
            </div>
            {discussions.map((discussion, index) => (
              <div key={index} className="mb-4 p-4 flex items-center bg-white rounded shadow">
                <div className="img -mr-6">
                  <img
                    src="https://via.placeholder.com/50" // Dummy image
                    className="w-12 h-12 rounded-full mr-4"
                  />
                </div>
                <div className="content">
                  <h3 className="text-xl font-semibold">{discussion.title}</h3>
                  <p className="text-gray-500">
                    {discussion.author} answered {discussion.time} • {discussion.members} members • {discussion.replies} replies
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Right Column */}
        <div className="space-y-10 mt-10">
          <div className="p-4 bg-white rounded shadow">
            <h2 className="text-xl font-semibold mb-4">Groups</h2>
            <div className="flex justify-between mb-4">
              <button className="text-orange-500">Newest</button>
              <button className="text-gray-500">Active</button>
              <button className="text-gray-500">Popular</button>
            </div>
            {groups.map((group, index) => (
              <div key={index} className="mb-8 flex items-center">
                <img
                  src="https://via.placeholder.com/50" // Dummy image
                  alt={group.name}
                  className="w-12 h-12 rounded-lg mr-4"
                />
                <div>
                  <h3 className="text-lg font-semibold">{group.name}</h3>
                  <p className="text-gray-500">{group.activity}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>


  );
}

export default DiscussionPage;
