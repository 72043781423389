import React from 'react'
import HomeHeader from '../components/HomeHeader'

import CourseCardtwo from '../components/CatlogPage/CourseContainertwo'
import HomeFooter from '../components/HomeComponents/HomeFooter'
import CourseCategory from '../components/CatlogPage/CourseCategory'
import CourseReview from '../components/CatlogPage/CourseReview'


const CatelogPage = () => {
  return (
    <>
   
    <HomeHeader/>
    <CourseCategory/>
    <CourseCardtwo/>
    <CourseReview/>
    <HomeFooter/>
    </>
  )
}

export default CatelogPage