import React, { useState } from 'react';
import { FaGraduationCap, FaClock, FaCertificate, FaClipboardCheck } from 'react-icons/fa';
import ReviewCard from "./ReviewCard";
import CourseCard from './CourseCard';
import LearnersAtWork from './LearnersAtWork';
import QAItems from './QAItems';
import Banner from './Banner';

const CourseContent = ({ title, duration }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-gray-300">
    <button
      className={`w-full text-left p-4 flex justify-between items-center bg-white hover:bg-orange-500 transition-colors duration-300 ${isOpen ? 'bg-gray-200' : ''}`}
      onClick={() => setIsOpen(!isOpen)}
    >
      <span className="text-lg font-semibold">{title}</span>
      <span className="text-lg">{isOpen ? '-' : '+'}</span>
    </button>
    {isOpen && (
      <div className="p-4 bg-gray-50">
        <ul className="list-disc list-inside">
          {duration.map((item, index) => (
            <li key={index} className="text-gray-600 mb-2">
              {item}
            </li>
          ))}
        </ul>
      </div>
    )}
  </div>
  );
};

const CourseInfo = () => {
  return (
<div className="bg-peach p-4 rounded-lg border border-gray-200 grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 gap-4 auto-rows-min">
  <div className="flex items-start space-x-4">
    <div className="text-xl text-gray-600">
      <FaGraduationCap className="h-6 w-6" />
    </div>
    <div>
      <p className="text-gray-600">Skill level</p>
      <p className="text-lg font-bold">Beginner</p>
    </div>
  </div>
  <div className="flex items-start space-x-4">
    <div className="text-xl text-gray-600">
      <FaClock className="h-6 w-6" />
    </div>
    <div>
      <p className="text-gray-600">Time to complete</p>
      <p className="text-lg font-bold">15 hours</p>
    </div>
  </div>
  <div className="flex items-start space-x-4">
    <div className="text-xl text-gray-600">
      <FaCertificate className="h-6 w-6" />
    </div>
    <div>
      <p className="text-gray-600">COC</p>
      <p className="text-lg font-bold">Included with paid plans</p>
    </div>
  </div>
  <div className="flex items-start space-x-4">
    <div className="text-xl text-gray-600">
      <FaClipboardCheck className="h-6 w-6" />
    </div>
    <div>
      <p className="text-gray-600">Prerequisites</p>
      <p className="text-lg font-bold">None</p>
    </div>
  </div>
</div>

  


  );
}; 
const CourseList = ({ courses }) => {
  // Utility function to truncate title
  const truncateTitle = (title, maxLength) => {
    if (title.length > maxLength) {
      return `${title.substring(0, maxLength)}...`;
    }
    return title;
  };

  return (
    <div className="flex justify-center mt-8 w-full">
      <div className="w-full">
        <h1 className="text-2xl font-bold p-6">Popular courses</h1>
        {courses.map((course, index) => (
          <div
            key={index}
            className="bg-white rounded-lg p-4 flex flex-col mb-4 relative"
          >
            <div className="flex items-center">
              <img
                src={course.image}
                alt={course.title}
                className="w-20 h-20 rounded-lg"
              />
              <div className="ml-4 flex flex-col justify-between h-full">
                <div className="relative group">
                  <p
                    className="text-base font-bold"
                    // Removed the title attribute
                  >
                    {truncateTitle(course.title, 20)}
                  </p>
                  {/* Hidden full title element */}
                  <span className="absolute left-0 top-15 bg-gray-800 text-white text-base rounded-lg p-1 opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-10">
  {course.title}
</span>

                </div>
                <div className="flex justify-between">
                  <p className="text-[14px] text-gray-500">
                    {course.duration} total hours
                  </p>
                  <p className="text-[14px] text-gray-500">
                    • Updated {course.updated}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex justify-between items-end mt-2">
              <div className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 text-yellow-500"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 17.27l6.18 3.73-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
                </svg>
                <p className="text-yellow-500 text-sm ml-1">{course.rating}</p>
                <p className="text-[12px] text-gray-500 ml-2">
                  {course.students} students
                </p>
              </div>
              <div className="text-right">
                <p className="text-xl font-bold text-red-500">₹{course.price}</p>
                <p className="text-sm text-gray-500 line-through">
                  ₹{course.originalPrice}
                </p>
              </div>
            </div>
            <hr className="mt-4" />
          </div>
        ))}
      </div>
    </div>
  );
};




const WhatYouWillLearn = ({ points }) => {
  const [showMore, setShowMore] = useState(false);
  const displayedPoints = showMore ? points : points.slice(0, 4);

  return (
    <div className="bg-white shadow-md rounded-lg p-6 mt-6 w-full max-w-6xl m-auto">
      <h2 className="text-2xl font-bold mb-4">What you'll learn</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 text-lg">
        {displayedPoints.map((point, index) => (
          <div key={index} className="flex items-start">
            <span className="mr-2 text-green-500">✔</span>
            <p>{point}</p>
          </div>
        ))}
      </div>
      <button
        className="text-blue-500 mt-4"
        onClick={() => setShowMore(!showMore)}
      >
        {showMore ? 'Show less' : 'Show more'}
      </button>
    </div>
  );
};

const courses = [
  {
    title: "The Complete 2024 Web Development Bootcamp",
    instructor: "Instructor",
    rating: 4.7,
    reviews: 392128,
    hours: 61.5,
    lectures: 373,
    price: 449,
    originalPrice: 3099,
    image: "https://via.placeholder.com/400x200.png?text=Course+Image",
  },
  {
    title: "iOS & Swift - The Complete iOS App Development",
    instructor: "Instructor",
    rating: 4.7,
    reviews: 91266,
    hours: 60.5,
    lectures: 542,
    price: 699,
    originalPrice: 4999,
    image: "https://via.placeholder.com/400x200.png?text=Course+Image",
  },
  {
    title: "The Complete Flutter Development Bootcamp with Dart",
    instructor: "Instructor",
    rating: 4.5,
    reviews: 54387,
    hours: 29,
    lectures: 217,
    price: 599,
    originalPrice: 4999,
    image: "https://via.placeholder.com/400x200.png?text=Course+Image",
  },
];

function App() {
  const courseSections = Array(5).fill({
    title: 'Introduction',
    duration: ['1 lecture • 5 minutes','1 lecture • 5 minutes']
  });

  const additionalCourses = [
    {
      image: 'https://via.placeholder.com/96',
      title: 'The Full Stack Web Development Bootcamp (Frontend & Backend)',
      duration: '41.5',
      updated: '6/2024',
      rating: '4.2',
      students: '41,894',
      price: '599',
      originalPrice: '2,699',
    },
    {
      image: 'https://via.placeholder.com/96',
      title: 'The Complete Web Development Course - Build 15 Projects',
      duration: '99.5',
      updated: '5/2024',
      rating: '4.4',
      students: '41,886',
      price: '699',
      originalPrice: '4,099',
    },
  ];

  const whatYouWillLearnPoints = [
    "Learn the core Java skills needed to apply for Java developer positions in just 14 hours.",
    "Be able to demonstrate your understanding of Java to future employers.",
    "Acquire essential java basics for transitioning to the Spring Framework, Java EE, Android development and more.",
    "Java 21 features coming soon!",
    "Be able to sit for and pass the Oracle Java Certificate exam if you choose.",
    "Learn industry 'best practices' in Java software development from a professional Java developer who has worked in the language for close to 25 years.",
    "Obtain proficiency in Java 17, as well as older versions including Java 11 and Java 8.",
  ];

  return (
<>
  <div className="min-h-screen flex flex-col p-0 sm:p-0 lg:p-8 custom:flex-row custom:space-x-4">
    <div className="custom:w-2/3 lg:w-3/4">
      <div className="rounded-lg p-4 sm:p-6 lg:p-8">
        <h1 className="text-3xl sm:text-4xl font-bold mb-4">Machine Learning Course</h1>
        <div className="flex flex-col sm:flex-row items-start sm:items-center text-gray-600 mb-4 space-y-2 sm:space-y-0 sm:space-x-4">
          <div className="flex items-center">
            <span className="text-yellow-500 text-xl">⭐⭐⭐⭐</span>
            <span className="text-gray-500 ml-2 text-sm sm:text-base">(7,786 Ratings) 68,654 students</span>
          </div>
          <div className="flex items-center ml-auto">
            <span className="text-gray-500 text-sm sm:text-base">Tutor:</span>
            <span className="text-gray-800 font-semibold ml-1 text-sm sm:text-base">Dileep Okush</span>
          </div>
        </div>

        <div className="relative mb-4 sm:mb-6 lg:mb-8">
          <div className="w-full  md:h-64 lg:h-80 bg-black flex items-center justify-center">
            <iframe
              className="w-full h-full"
              src="https://www.youtube.com/embed/example"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
        <CourseInfo />
        <div className="text-base sm:text-lg mb-4 mt-6 sm:mt-8">
          <p>A machine learning course typically covers fundamental concepts such as supervised and unsupervised learning, data preprocessing, and model evaluation. Students learn to implement algorithms like linear regression, decision trees, and neural networks using programming languages like Python.</p>
        </div>
        <div className="bg-white shadow-md rounded-lg p-4 sm:p-6 mt-4 sm:mt-6 w-full">
          <h2 className="text-xl sm:text-2xl font-bold mb-4">Course content</h2>
          <div className="border border-gray-300 rounded-lg">
            {courseSections.map((section, index) => (
              <CourseContent key={index} {...section} />
            ))}
          </div>
        </div>
      </div>
    </div>
    <div className="custom:w-1/3 lg:w-1/4 sm:m-6 lg:ml-6">
      <div className="bg-white shadow-md rounded-lg p-4 sm:p-6 flex flex-col items-center">
        <div>
          <p className="text-2xl sm:text-3xl font-bold">$12,000</p>
          <ul className="text-gray-500 list-disc list-inside text-sm sm:text-base">
            <li>7 hours on-demand video</li>
            <li>20 downloadable resources</li>
            <li>Access on mobile and TV</li>
            <li>50 articles</li>
            <li>8 coding exercises</li>
            <li>Certificate on completion</li>
          </ul>
        </div>
        <div className="mt-4">
          <button className="bg-orange-500 text-white px-4 py-2 rounded-lg shadow-md hover:bg-orange-600 m-1">Buy now</button>
          <button className="m-1 bg-gray-200 text-gray-700 px-4 py-2 rounded-lg shadow-md hover:bg-gray-300">Add to cart</button>
        </div>
      </div>
      <CourseList courses={additionalCourses} />
    </div>
  </div>

  <div className='w-full'>
    <WhatYouWillLearn points={whatYouWillLearnPoints} />
  </div>

  <div className="rounded-lg p-4 sm:p-6 lg:p-8 mt-4 sm:mt-6 lg:mt-8 w-full max-w-screen-xl mx-auto">
  <div className="rounded-lg p-4    mt-4 w-full max-w-screen-xl mx-auto">
    <div className='p-4 sm:p-6 md:p-8 lg:p-10 xl:p-12 bg-white rounded-lg shadow-md lg:shadow-lg xl:shadow-xl'>
      <div className="flex flex-col sm:flex-row items-center sm:items-start">
        <img
          src="https://via.placeholder.com/96"
          alt="Instructor"
          className="w-20 h-20 sm:w-28 sm:h-28 md:w-32 md:h-32 lg:w-36 lg:h-36 rounded-full"
        />
        <div className="mt-4 sm:mt-0 sm:ml-6 text-center sm:text-left">
          <h2 className="text-xl sm:text-2xl md:text-3xl font-bold text-orange-600">
            Edwin Einsen Vásquez Velásquez
          </h2>
          <p className="text-gray-600 text-sm sm:text-base md:text-lg">Desarrollador y Consultor</p>
          <div className="mt-2 flex justify-center sm:justify-start items-center">
            <span className="text-yellow-500 text-lg sm:text-xl md:text-2xl mr-2">⭐</span>
            <span className="text-gray-800 font-bold text-sm sm:text-base md:text-lg">4.6 Instructor Rating</span>
          </div>
          <div className="mt-1 flex justify-center sm:justify-start items-center">
            <span className="text-gray-800 font-bold text-sm sm:text-base md:text-lg mr-2">🏅</span>
            <span className="text-gray-600 text-sm sm:text-base md:text-lg">198,786 Reviews</span>
          </div>
          <div className="mt-1 flex justify-center sm:justify-start items-center">
            <span className="text-gray-800 font-bold text-sm sm:text-base md:text-lg mr-2">👥</span>
            <span className="text-gray-600 text-sm sm:text-base md:text-lg">867,384 Students</span>
          </div>
          <div className="mt-1 flex justify-center sm:justify-start items-center">
            <span className="text-gray-800 font-bold text-sm sm:text-base md:text-lg mr-2">▶️</span>
            <span className="text-gray-600 text-sm sm:text-base md:text-lg">1 Course</span>
          </div>
        </div>
      </div>
      <div className="mt-4">
        <p className="text-gray-700 text-sm sm:text-base md:text-lg">
          Tengo 6 años desarrollando software (escritorio y web), inicié con Java y PHP, pero con Ruby fue amor a primera línea de código, por lo que desde hace ya casi 4 años vengo trabajando con esta tecnología, tuve una pequeña aventura con Angular durante 8 meses, donde vi que el Frontend también es complicado, estoy enfocado más al Backend pero me gusta aprender de todo un poco, mejorando día a día.
        </p>
      </div>
    </div>
</div>


    <ReviewCard />
    <div className="mt-8 w-full">
      <h1 className="text-2xl font-bold p-4 sm:p-6 text-center">Popular Courses</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-4 sm:gap-6 justify-items-center">
        {courses.map((course, index) => (
          <CourseCard key={index} course={course} />
        ))}
      </div>
    </div>
    <div>
      <LearnersAtWork />
      <QAItems />
    </div>
  </div>
 <Banner/>
</>

  );
}

export default App;
