// CoursesContainer.js
import React from 'react';
import CourseCard from './CourseCard';
import image1 from "../../assets/code1.png"
import image4 from '../../assets/code1.png';
import image2 from '../../assets/code2.jpeg';
import image3 from '../../assets/code3.jpeg';


const CoursesContainer = () => {
  const courses = [
    {
    imageSrc: image1,
      title: 'Complete JavaScript course - Beginners to advanced',
      description: "Beginner's guide",
      rating: '4.5',
      link: '#'
    },
    {
      imageSrc: image2,
      title: 'Complete JavaScript course - Beginners to advanced',
      description: "Beginner's guide",
      rating: '4.5',
      link: '#'
    },
    {
      imageSrc: image3,
      title: 'Complete JavaScript course - Beginners to advanced',
      description: "Beginner's guide",
      rating: '4.5',
      link: '#'
    },
    {
      imageSrc: image4,
      title: 'Machine Learning & Data Science',
      description: "Beginner's guide",
      rating: '4.5',
      link: '#'
    }
  ];

  return (
    <div className="courses-container bigger">
      <div className="card-header">
        <h2 className="courses-heading large lg:ml-10 font-bold">Popular Courses</h2>
        <div className="upper">
          <a className="view lg:mr-10" href="#">View all</a>
        </div>
      </div>
      <div className="card-container big-container">
        {courses.map((course, index) => (
          <CourseCard
            key={index}
            imageSrc={course.imageSrc}
            title={course.title}
            description={course.description}
            rating={course.rating}
            link={course.link}
          />
        ))}
      </div>
    </div>
  );
};

export default CoursesContainer;
