// CourseCard.js
import React from 'react';
import { faStar} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CourseCardFour = ({ imageSrc, title, description, rating, link }) => {
  return (
    <div className="cards">
      <img src={imageSrc} alt={title} />
      <h4>{title}</h4>
      <p className="fourty">{description}</p>
      <div className="top">
      <i className="fas fa-star icon star fa-sm" title="Star">  <FontAwesomeIcon icon={faStar} className="icon greater-than" title="Greater Than" />{ rating}</i>
        <a className="explore-link" href={link}>Explore</a>
      </div>
    </div>
  );
};

export default CourseCardFour;
