import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faBars, faTimes, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import LoginModal from './../modals/LoginModal';
import logo from "../assets/cclogo.png";

const Navbar = () => {
  const [darkMode, setDarkMode] = useState(() => {
    const savedMode = localStorage.getItem('darkMode');     // Retrieve dark mode preference from local storage

    return savedMode ? JSON.parse(savedMode) : false;
  });
  const [navbarActive, setNavbarActive] = useState(false);
  const [showModal, setShowModal] = useState(false);

  // Apply dark mode preference when the component mounts
  useEffect(() => {
    const body = document.body;
    body.classList.toggle('dark-mode', darkMode);
    const sun = document.querySelector('.sun');
    const moon = document.querySelector('.moon');
    if (darkMode) {
      body.classList.add('dark-mode');
      sun.style.backgroundColor = '';
      moon.style.backgroundColor = '#FF5F00';
    } else {
      body.classList.remove('dark-mode');
      sun.style.backgroundColor = '#FF5F00';
      moon.style.backgroundColor = '';
    }
  }, [darkMode]);


  // Save dark mode preference to local storage
  useEffect(() => {
    localStorage.setItem('darkMode', JSON.stringify(darkMode));
  }, [darkMode]);


  // showing modal after 3 seconds and closing after 3 seconds
  useEffect(() => {
    const openTimer = setTimeout(() => {
      setShowModal(true);
      // Set another timeout to close the modal after 3 seconds
      const closeTimer = setTimeout(() => {
        setShowModal(false);
      }, 3000);
      // Clear the close timer when the component is unmounted or before opening the modal again
      return () => clearTimeout(closeTimer);
    }, 3000);
    // Clear the open timer when the component is unmounted or before setting it again
    return () => clearTimeout(openTimer);
  }, []);


  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const toggleNavbar = () => {
    setNavbarActive(!navbarActive);
  };

  useEffect(() => {
    const container = document.querySelector('.secondary-navbar-container');

    const scrollLeft = () => {
      if (container) {
        container.scrollBy({ left: -200, behavior: 'smooth' });
      }
    };

    const scrollRight = () => {
      if (container) {
        container.scrollBy({ left: 200, behavior: 'smooth' });
      }
    };

    const leftArrow = document.querySelector('.left-arrow');
    const rightArrow = document.querySelector('.right-arrow');

    if (leftArrow && rightArrow) {
      leftArrow.addEventListener('click', scrollLeft);
      rightArrow.addEventListener('click', scrollRight);

      return () => {
        leftArrow.removeEventListener('click', scrollLeft);
        rightArrow.removeEventListener('click', scrollRight);
      };
    }
  }, []);

  useEffect(() => {
    const toggleButton = document.querySelector('.navbar-toggle');
    const navbarLeft = document.querySelector('.navbar-left');
    const navbarRight = document.querySelector('.navbar-right');
    const stickyNav = document.querySelector('.sticky-nav');

    if (toggleButton) {
      const handleToggle = () => {
        if (navbarLeft) navbarLeft.classList.toggle('active');
        if (navbarRight) navbarRight.classList.toggle('active');
        if (stickyNav) stickyNav.classList.toggle('non-sticky');
      };

      toggleButton.addEventListener('click', handleToggle);

      return () => {
        toggleButton.removeEventListener('click', handleToggle);
      };
    }
  }, []);

  // to open and close modal
  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="sticky-nav">
      <nav className={`navbar navbar-margin ${navbarActive ? 'active' : ''}`} aria-label="Main navbar">
        <div className="navbar-left">
          <a className="nav-link" href="#">
            <div className="user-id">
              <div className="user-name">Home</div>
            </div>
          </a>
          <a className="nav-link" href="#">
            <div className="user-id">
              <div className="user-name">DSA</div>
              <div className="dropdown-arrow">
                <FontAwesomeIcon icon={faChevronDown} />
              </div>
              <div className="dropdown-menu">
                <ul>
                  <li>Settings</li>
                  <li>Sports</li>
                  <li>Log Out</li>
                </ul>
              </div>
            </div>
          </a>
          <a className="nav-link" href="#">
            <div className="user-id">
              <div className="user-name">Web Development</div>
              <div className="dropdown-arrow">
                <FontAwesomeIcon icon={faChevronDown} />
              </div>
              <div className="dropdown-menu">
                <ul>
                  <li>Settings</li>
                  <li>Sports</li>
                  <li>Log Out</li>
                </ul>
              </div>
            </div>
          </a>
          <a className="nav-link" href="#">
            <div className="user-id">
              <div className="user-name">ML & Data Science</div>
              <div className="dropdown-arrow">
                <FontAwesomeIcon icon={faChevronDown} />
              </div>
              <div className="dropdown-menu">
                <ul>
                  <li>Settings</li>
                  <li>Sports</li>
                  <li>Log Out</li>
                </ul>
              </div>
            </div>
          </a>
        </div>
        <div className="navbar-center">
          <a className="navbar-brand" href="#">
            {/* <img src={logo} alt="logo" className='h-14 w-30'/> */}
            <h1 className='text-2xl text-orange-500 font-extrabold'>Coding Community</h1>
          </a>
        </div>
        <div className="navbar-right">
          <a className="nav-link" href="#">
            <div className="user-id">
              <div className="user-name">Interviews</div>
              <div className="dropdown-arrow">
                <FontAwesomeIcon icon={faChevronDown} />
              </div>
              <div className="dropdown-menu">
                <ul>
                  <li>Settings</li>
                  <li>Sports</li>
                  <li>Log Out</li>
                </ul>
              </div>
            </div>
          </a>
          <a className="nav-link" href="#">
            <div className="user-id">
              <div className="user-name">Home</div>
            </div>
          </a>
          <a className="nav-link" href="#">
            <div className="user-id">
              <div className="user-name">Home</div>
            </div>
          </a>
          <div className="nav-link nav-box dark-mode-toggle" onClick={toggleDarkMode}>
            <span className="sun">🔆</span>
            <span className="moon">🌙</span>
          </div>
          <a className="nav-link nav-box sign-box" href="#" onClick={openModal}>Sign In</a>
          <img src="https://via.placeholder.com/40" className="h-14 w-14 rounded-full p-2" alt="Placeholder" />
          </div>
        <div className="navbar-toggle" onClick={toggleNavbar}>
          <span className="navbar-toggle-icon">
            <FontAwesomeIcon icon={navbarActive ? faTimes : faBars} />
          </span>
        </div>
      </nav>
      <nav className="secondary-navbar" aria-label="second navbar">
        <div className="scroll-arrow left-arrow">
          <FontAwesomeIcon icon={faAngleLeft} className="icon less-than" title="Less Than" />
        </div>
        <div className="secondary-navbar-container">
          <div className="secondary-navbar-left">
            <a className="secondary-nav-link nav-link-hover" href="#">Trending</a>
            <a className="secondary-nav-link nav-link-hover" href="#">HTML</a>
            <a className="secondary-nav-link nav-link-hover" href="#">CSS</a>
            <a className="secondary-nav-link nav-link-hover" href="#">Data Structures</a>
            <a className="secondary-nav-link nav-link-hover" href="#">Machine Learning</a>
            <a className="secondary-nav-link nav-link-hover" href="#">Data Science</a>
            <a className="secondary-nav-link nav-link-hover" href="#">Algorithm</a>
            <a className="secondary-nav-link nav-link-hover" href="#">Data Script</a>
            <a className="secondary-nav-link nav-link-hover" href="#">PHP</a>
            <a className="secondary-nav-link nav-link-hover" href="#">SQL</a>
            <a className="secondary-nav-link nav-link-hover" href="#">Kotlin</a>
            <a className="secondary-nav-link nav-link-hover" href="#">CSS Frameworks</a>
            <a className="secondary-nav-link nav-link-hover" href="#">JavaScript</a>
            <a className="secondary-nav-link nav-link-hover" href="#">JSON</a>
            <a className="secondary-nav-link nav-link-hover" href="#">PHP</a>
            <a className="secondary-nav-link nav-link-hover" href="#">SQL</a>
            <a className="secondary-nav-link nav-link-hover" href="#">Kotlin</a>
            <a className="secondary-nav-link nav-link-hover" href="#">CSS Frameworks</a>
            <a className="secondary-nav-link nav-link-hover" href="#">JavaScript</a>
            <a className="secondary-nav-link nav-link-hover" href="#">JSON</a>     <a className="secondary-nav-link nav-link-hover" href="#">PHP</a>
            <a className="secondary-nav-link nav-link-hover" href="#">SQL</a>
            <a className="secondary-nav-link nav-link-hover" href="#">Kotlin</a>
            <a className="secondary-nav-link nav-link-hover" href="#">CSS Frameworks</a>
            <a className="secondary-nav-link nav-link-hover" href="#">JavaScript</a>
            <a className="secondary-nav-link nav-link-hover" href="#">JSON</a>
          </div>
        </div>
        <div className="scroll-arrow right-arrow">
          <FontAwesomeIcon icon={faAngleRight} className="icon greater-than" title="Greater Than" />
        </div>
      </nav>
      <div className={`modal-login ${showModal ? 'show' : ''}`}>
        <LoginModal show={showModal} onClose={closeModal} />
      </div>
    </div>
  );
};

export default Navbar;
