// CustomInputGroup.js
import React, { useState } from 'react';


const CustomInputGroup = () => {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSearchClick = () => {
    // Handle the search button click event
    console.log('Search for:', inputValue);
  };

  return (
    <div className="welcome-container">
      <div className="welcome-content">
        <div className="upar">
          <p className="welcome-headings">
            Welcome to <span className="community-name">Coding Community</span>
          </p>
          <p className="welcome-description">
            Join our vibrant online community where you learn, share, and grow together
          </p>
        </div>
        <div className="custom-input-group">
          <input
            type="text"
            className="custom-form-control"
            placeholder="Learn Python, HTML, CSS"
            value={inputValue}
            onChange={handleInputChange}
          />
          <button className="custom-btnss" type="button" onClick={handleSearchClick}>
            Search
          </button>
        </div>
      </div>
    </div>
  );
};

export default CustomInputGroup;
