import React, { useState } from 'react';
import like  from '../assets/icon/like.svg';
import comment from '../assets/icon/chat.svg';
import share from '../assets/icon/share.svg'

const Post = () => {
  const dummyImages = [
    'https://via.placeholder.com/32',
    'https://via.placeholder.com/32',
    'https://via.placeholder.com/32',
    'https://via.placeholder.com/32'
  ];

  return (
    <div className="p-4 rounded-lg">
      <div className="relative w-full h-64 rounded overflow-visible">
        <img
          src="https://via.placeholder.com/1500x300"
          alt="Header"
          className="w-full h-full object-cover"
        />
        <div className="absolute flex top-44 z-50 lg:left-20 p-4 items-center">
          <img
            src="https://via.placeholder.com/100"
            alt="Organizer"
            className="w-32 h-40"
          />
          <div>
            <p className="text-gray-600 ml-10 mt-20 text-lg">Public • Group</p>
            <p className="text-gray-600 ml-10 text-lg">
              Organizer:
              <img src="https://via.placeholder.com/30" alt="Organizer" className="inline w-8 h-8 rounded-full ml-2" />
            </p>
          </div>
        </div>
      </div>
      <h2 className="text-lg mt-36 font-bold mb-6">
        <div className='bg-orange-300 mb-10 h-[1px]'></div>
        Q. I'm struggling with understanding how promises work in JavaScript. Can anyone explain them in simple terms?
      </h2>
      <div className="flex flex-col md:flex-row justify-around mb-4">
        <img src="https://via.placeholder.com/150" alt="post" className="w-full md:w-[40%] h-64 rounded mb-2 md:mb-0 md:mr-2" />
      </div>
      <div className="flex flex-col md:flex-row items-center space-y-2 md:space-y-0 md:space-x-2">
        <div className="flex -space-x-2">
          {dummyImages.map((src, index) => (
            <img
              key={index}
              className="w-8 h-8 rounded-full border-2 border-white"
              src={src}
              alt={`avatar-${index}`}
            />
          ))}
        </div>
        <div className="text-gray-600">+ 233 Likes</div>
        <div className="flex space-x-4 md:ml-auto ">
          <div className="flex items-center space-x-1 ">
            <img className="w-5 h-5 " src={like} alt="like-icon" />
            <span className="text-gray-600">Like</span>
          </div>
          <div className="flex items-center space-x-1">
            <img className="w-5 h-5" src={comment} alt="comment-icon" />
            <span className="text-gray-600">6 Comments</span>
          </div>
          <div className="flex items-center space-x-1">
            <img className="w-5 h-5" src={share} alt="share-icon" />
            <span className="text-gray-600">6 Shares</span>
          </div>
        </div>
      </div>
      <div className="mb-2 mt-8">
        <input 
          type="text"
          placeholder="Write a comment"
          className="w-full p-2 border rounded-lg"
        />
      </div>
      <CommentSection />
    </div>
  );
};

const CommentSection = () => {
  const [showAllComments, setShowAllComments] = useState(false);

  const comments = [
    {
      author: "Jackson Jones",
      time: "Today at 7:13 pm",
      text: "Sure, Alex! Promises are a way to handle asynchronous operations.",
    },
    {
      author: "Samantha Lee",
      time: "Today at 7:15 pm",
      text: "Think of a promise like a placeholder for a future value.",
    },
    {
      author: "Alex Smith",
      time: "Today at 7:20 pm",
      text: "Promises have three states: pending, fulfilled, and rejected.",
    },
  ];

  const displayedComments = showAllComments ? comments : [comments[0]];

  return (
    <div className="mt-10">
      {displayedComments.map((comment, index) => (
        <div key={index} className="flex items-start space-x-2 mb-4">
          <img src="https://via.placeholder.com/40" alt="avatar" className="w-10 h-10 rounded-full" />
          <div className="bg-gray-100 p-2 rounded-lg w-full">
            <span className="block font-semibold">{comment.author}</span>
            <span className="block text-sm text-gray-400 mb-2">{comment.time}</span>
            <span className="block text-sm text-gray-600">{comment.text}</span>
          </div>
        </div>
      ))}
      <button className="text-orange-500 text-sm mt-1" onClick={() => setShowAllComments(!showAllComments)}>
        {showAllComments ? 'Hide comments' : 'View all comments'}
      </button>
    </div>
  );
};

const GroupList = () => {
  const groups = [
    { name: "Machine Learning", activity: "Active 1 year ago" },
    { name: "Machine Learning", activity: "Active 1 year ago" },
    { name: "Machine Learning", activity: "Active 1 year ago" },
    { name: "Machine Learning", activity: "Active 1 year ago" },
    { name: "Machine Learning", activity: "Active 1 year ago" },
  ];

  return (
    <div className="p-4 rounded-lg">
      <div className="space-y-10 mt-4">
        <div className="p-4 bg-white rounded shadow">
          <h2 className="text-xl font-semibold mb-4">Groups</h2>
          <div className="flex justify-between mb-4">
            <button className="text-orange-500">Newest</button>
            <button className="text-gray-500">Active</button>
            <button className="text-gray-500">Popular</button>
          </div>
          {groups.map((group, index) => (
            <div key={index} className="mb-8 flex items-center">
              <img
                src="https://via.placeholder.com/50"
                alt={group.name}
                className="w-12 h-12 rounded-lg mr-4"
              />
              <div>
                <h3 className="text-lg font-semibold">{group.name}</h3>
                <p className="text-gray-500">{group.activity}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const QuestionDiscussion = () => {
  return (
    <div className="flex flex-col md:flex-row p-4">
      <div className="w-full md:w-2/3 max-w-5xl lg:ml-4">
        <Post />
      </div>
      <div className="w-full md:w-1/3 mt-4 md:mt-0 md:ml-4">
        <GroupList />
      </div>
    </div>
  );
};

export default QuestionDiscussion;
