import React from 'react';

const AboutPage = () => {
  return (
    <div className="container-lg py-1">
      <div className="row min-vh-100 align-items-center align-content-center">
        <div className="col-md-6 mt-md-0">
          <div className="home-img text-center">
            <img
              src="/image.png"
              alt="profile img"
              style={{
                borderRadius: '5%',
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                border: '10px gray',
              }}
            />
          </div>
        </div>
        <div className="col-md-6 mt-md-0 order-md-first">
          <div className="home-text">
            <h1 className="text-secondary text-uppercase fs-1 fw-bold">Coding Community</h1>
            <h2 className="fs-4">What we do</h2>
            <p className="text-muted">
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nihil veritatis iste eum amet praesentium modi quam perferendis, harum nulla doloribus reprehenderit ullam voluptatum ex, blanditiis ab unde temporibus quis sapiente aspernatur eos magni repellat, cum vero distinctio! Neque, reiciendis corporis minus quos ea consectetur iure, excepturi, blanditiis modi odio nam totam velit commodi tempora! Distinctio, sed impedit? Facere recusandae necessitatibus dolores maiores delectus cumque! Corporis officiis nihil doloribus eos eveniet illum obcaecati enim quia. Repellendus repudiandae aut blanditiis reiciendis architecto debitis corporis recusandae aliquid! Porro nam id magnam hic aliquam inventore, illo rem suscipit eius nisi vel, sunt, eligendi est!
            </p>
            <a href="#" className="btn btn-secondary px-3">Read More</a>
          </div>
        </div>
      </div>

      <div className="max-w-4xl mx-auto p-6 bg-background text-foreground">
        <h2 className="text-2xl font-bold mb-4 text-center text-secondary">What features we provide</h2>
        <h1 className="text-xl text-uppercase fs-1 mb-6 text-center">Core Features</h1>
        <div className="row">
          <div className="col-lg-6">
            <ul>
              <li className="flex items-start">
                <span className="text-accent mr-2">✔️</span>
                <span>Engage with a worldwide community of inquisitive and imaginative individuals.</span>
              </li>
              <li className="flex items-start">
                <span className="text-accent mr-2">✔️</span>
                <span>Engage with a worldwide community of inquisitive and imaginative individuals.</span>
              </li>
              <li className="flex items-start">
                <span className="text-accent mr-2">✔️</span>
                <span>Learn a skill of your choice from the experts around the world from various industries.</span>
              </li>
              <li className="flex items-start">
                <span className="text-accent mr-2">✔️</span>
                <span>Learn a skill of your choice from the experts around the world from various industries.</span>
              </li>
            </ul>
          </div>
          <div className="col-lg-6">
            <ul>
              <li className="flex items-start">
                <span className="text-accent mr-2">✔️</span>
                <span>Learn a skill of your choice from the experts around the world from various industries.</span>
              </li>
              <li className="flex items-start">
                <span className="text-accent mr-2">✔️</span>
                <span>Learn a skill of your choice from the experts around the world from various industries.</span>
              </li>
              <li className="flex items-start">
                <span className="text-accent mr-2">✔️</span>
                <span>Learn a skill of your choice from the experts around the world from various industries.</span>
              </li>
              <li className="flex items-start">
                <span className="text-accent mr-2">✔️</span>
                <span>Learn a skill of your choice from the experts around the world from various industries.</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
