import React from 'react';

const ReviewCard = ({ initial, name, date, rating, review }) => {
  return (
    <div className="flex flex-col p-4 border border-gray-300 rounded-lg w-full h-full">
      <div className="flex items-center mb-2">
        <div className="w-10 h-10 bg-gray-400 rounded-full flex items-center justify-center text-white font-bold">
          {initial}
        </div>
        <div className="ml-3">
          <div className="font-bold">{name}</div>
          <div className="text-gray-500 text-sm">{date}</div>
        </div>
      </div>
      <div className="flex items-center mb-2">
        <div className="flex items-center">
          {[...Array(rating)].map((_, index) => (
            <svg
              key={index}
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-yellow-500"
              fill="currentColor"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 17.27l6.18 3.73-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
            </svg>
          ))}
          {[...Array(5 - rating)].map((_, index) => (
            <svg
              key={index}
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-gray-300"
              fill="currentColor"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 17.27l6.18 3.73-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
            </svg>
          ))}
        </div>
        <div className="ml-2 text-gray-500 text-sm">{date}</div>
      </div>
      <div className="mb-4 text-gray-700 flex-grow">{review}</div>
      <div className="flex items-center text-gray-500 text-sm mt-auto">
        <div>Helpful?</div>
        <button className="ml-2 flex items-center">👍</button>

        <button className="ml-2 flex items-center">👎</button>


      </div>
    </div>
  );
};

const CourseReviews = () => {
  const reviews = [
    {
      initial: 'ZJ',
      name: 'Ziebhar J.',
      date: 'a week ago',
      rating: 5,
      review: 'I learned a lot from this crash course.'
    },
    {
      initial: 'EC',
      name: 'Earl Gian C.',
      date: 'a week ago',
      rating: 5,
      review: 'Course has a really nice progression of topics that is sensible and well-thought of. To expand, this course does not just throw concepts and syntax to your face, you are presented with use-cases and real-world scenarios that help solidify your understanding.'
    },
    {
        initial: 'ZJ',
        name: 'Ziebhar J.',
        date: 'a week ago',
        rating: 4,
        review: 'I learned a lot from this crash course.'
      },
  ];

  return (
    <div className=" max-w-6xl mx-auto">
      <div className="flex items-center mb-6 mt-10">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 text-yellow-500"
          fill="currentColor"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 17.27l6.18 3.73-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
        </svg>
        <div className="ml-2 text-lg font-bold">4.7 course rating</div>
        <div className="ml-2 text-gray-500 font-bold">• 312K ratings</div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {reviews.map((review, index) => (
          <ReviewCard
            key={index}
            initial={review.initial}
            name={review.name}
            date={review.date}
            rating={review.rating}
            review={review.review}
          />
        ))}
      </div>
      <div className="mt-6">
  <button className='block mx-auto p-2 border-2'>
    Show all reviews
  </button>
</div>

    </div>
  );
};

export default CourseReviews;











