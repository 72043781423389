import React from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '../styles/App.css'; // Ensure you have your styles imported
import UserCircleIcon from '../assets/icon/usercircle.svg';
import BoldIcon from '../assets/icon/bold.svg';
import UnderlineIcon from '../assets/icon/underline.svg';
import StrikethroughIcon from '../assets/icon/striketrough.svg';
import LinkIcon from '../assets/icon/link.svg';
import ImageIcon from '../assets/icon/image.svg';
import QuoteRightIcon from '../assets/icon/quoteright.svg';
import ListUlIcon from '../assets/icon/listul.svg';
import ListOlIcon from '../assets/icon/listol.svg';
import CodeIcon from '../assets/icon/code.svg';
import TableIcon from '../assets/icon/table.svg';

const DiscussionModal = ({ onClose }) => { // Accept onClose prop

    const comments = [
        {
            author: 'Binary pie',
            date: '3 months ago',
            text: 'awesome and clear',
            likes: 0,
            dislikes: 0
        },
        {
            author: 'Binary pie',
            date: '3 months ago',
            text: 'In jupyter notebook function\'s docstring shows a \'/\' sometimes. What is that?',
            likes: 0,
            dislikes: 0
        }
    ];
    const handleContainerClick = (e) => {
        e.stopPropagation();
      };
    

    return (
        <div id="discussionModal" className="discussion-overlay" style={{ display: 'flex' }} >
            <div className="discussion-container" >
                <div className="discussion-header">
                    <h2>Discussions <span>(31 Threads)</span></h2>
                    <span className="discussion-close-btn" onClick={onClose}>&times;</span>
                </div>
                <div className="discussion-body" >
                <div className="head">
    <img src={UserCircleIcon} alt="User" width="30" height="30" />
    <div className="comment-input">
      <div className="comment-input-icons">
        <img src={BoldIcon} alt="Bold" width="16" height="16" />
        <img src={UnderlineIcon} alt="Underline" width="16" height="16" />
        <img src={StrikethroughIcon} alt="Strikethrough" width="16" height="16" />
        <img src={LinkIcon} alt="Link" width="16" height="16" />
        <img src={ImageIcon} alt="Image" width="16" height="16" />
        <img src={QuoteRightIcon} alt="Quote" width="16" height="16" />
        <img src={ListUlIcon} alt="Unordered List" width="16" height="16" />
        <img src={ListOlIcon} alt="Ordered List" width="16" height="16" />
        <img src={CodeIcon} alt="Code" width="16" height="16" />
        <img src={TableIcon} alt="Table" width="16" height="16" />
      </div>
      <textarea placeholder="You can use @ to mention any user and # to add tags in the comment."></textarea>
    </div>
  </div>
                    <button className="left-side">Submit</button>
                    <div className="comments">
                        {comments.map((comment, index) => (
                            <div className="comment" key={index}>
                                <div className="low">
                                <img src={UserCircleIcon} alt="User" width="30" height="30" />
                                <div className="comment-author">{comment.author} <span>{comment.date}</span></div>
                                </div>
                                <div className="comment-text">{comment.text}</div>
                                <div className="comment-actions">
                                    <button>👍 {comment.likes}</button>
                                    <button>👎 {comment.dislikes}</button>
                                    <button>Reply</button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DiscussionModal;
