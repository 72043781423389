import React from 'react';

const CourseCard = ({ course }) => {
  return (
    <div className="bg-white shadow-md rounded-lg overflow-hidden w-72 h-auto grid grid-rows-[auto_1fr_auto]">
      <img src={course.image} alt={course.title} className="w-full h-40 object-cover row-span-1" />
      <div className="p-4 grid gap-2 row-span-2">
        <div>
          <h2 className="text-xl font-bold">{course.title}</h2>
          <p className="text-gray-700">{course.instructor}</p>
        </div>
        <div className="my-2">
          <div className="flex ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-yellow-500"
              fill="currentColor"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 17.27l6.18 3.73-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
            </svg>
            <span className="text-yellow-500 font-bold ml-1">{course.rating}</span>
            <span className="text-gray-500 ml-2">({course.reviews.toLocaleString()})</span>
          </div>
          <p className="text-gray-500">{course.hours} total hours · {course.lectures} lectures · All Levels</p>
        </div>
        <div className="flex justify-between items-center">
          <span className="text-lg font-bold text-green-600">₹{course.price}</span>
          <span className="line-through text-gray-500">₹{course.originalPrice}</span>
        </div>
      </div>
    </div>
  );
}

export default CourseCard;
