import React from 'react';
import CountUp from 'react-countup';

const OnlineSkills = () => {
  return (
    <>
      <div className="relative flex flex-col items-start justify-center h-[460px]  bg-cover bg-center" style={{ backgroundImage: 'url(https://via.placeholder.com/1200x800)' }}>
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="relative z-10 ml-4 sm:ml-10 -mt-10 sm:-mt-20">
          <h1 className="text-4xl sm:text-6xl md:text-8xl text-white">SKILLS ONLINE</h1>
          <p className="text-lg sm:text-2xl mt-2 text-white">Online Courses Taught by Industry Titans!</p>
        </div>
      </div>
      <div className="relative z-10 -mt-10 flex justify-center">
        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 gap-4 w-full max-w-screen-lg px-4">
          <div className="bg-white p-3 sm:p-5 rounded-lg shadow-lg text-center w-full">
            <h2 className="text-2xl sm:text-3xl font-bold text-red-500">
              <CountUp end={100} duration={3} />+
            </h2>
            <p className="mt-1 sm:mt-2 text-gray-700">INSTRUCTORS</p>
          </div>
          <div className="bg-white p-3 sm:p-5 rounded-lg shadow-lg text-center w-full">
            <h2 className="text-2xl sm:text-3xl font-bold text-red-500">
              <CountUp end={500} duration={3} />+
            </h2>
            <p className="mt-1 sm:mt-2 text-gray-700">COURSES</p>
          </div>
          <div className="bg-white p-3 sm:p-5 rounded-lg shadow-lg text-center w-full">
            <h2 className="text-2xl sm:text-3xl font-bold text-red-500">
              <CountUp end={1} duration={3} prefix="FREE" />
            </h2>
            <p className="mt-1 sm:mt-2 text-gray-700">CERTIFICATIONS</p>
          </div>
          <div className="bg-white p-3 sm:p-5 rounded-lg shadow-lg text-center w-full">
            <h2 className="text-2xl sm:text-3xl font-bold text-red-500">
              <CountUp end={2000} duration={3} />+
            </h2>
            <p className="mt-1 sm:mt-2 text-gray-700">HAPPY MEMBERS</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default OnlineSkills;
