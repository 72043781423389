import React from 'react';

import AboutUs from '../components/CommunityComp/Aboutus';
import HomeHeader from "../components/HomeHeader"

const WhatWeOffer = () => {
  return (
    <>
      <HomeHeader />
      <AboutUs />
      <section className="py-16 px-4 bg-gray-50 dark-mode:bg-gray-900 text-center">
        <h2 className="text-4xl font-bold mb-12 text-gray-900 dark-mode:text-gray-100">What We Offer</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-8">
          <div className="flex flex-col items-center p-6 bg-white dark-mode:bg-gray-800 shadow-lg rounded-lg">
            <div className="text-orange-500 text-5xl mb-4">
              <i className="fas fa-laptop-code"></i>
            </div>
            <h3 className="text-xl font-semibold mb-2 text-gray-900 dark-mode:text-gray-100">Online learning platform</h3>
            <p className="text-gray-700 dark-mode:text-gray-300">
              Access a wide range of tutorials, courses, and articles tailored to help you learn and grow, whether you're just starting out or looking to refine your skills.
            </p>
          </div>
          <div className="flex flex-col items-center p-6 bg-white dark-mode:bg-gray-800 shadow-lg rounded-lg">
            <div className="text-orange-500 text-5xl mb-4">
              <i className="fas fa-newspaper"></i>
            </div>
            <h3 className="text-xl font-semibold mb-2 text-gray-900 dark-mode:text-gray-100">Access to latest job opening</h3>
            <p className="text-gray-700 dark-mode:text-gray-300">
              Explore the latest job listings from top companies. We provide information lor about the latest job opportunities from different companies, bringing you the best opportunities to advance your career.
            </p>
          </div>
          <div className="flex flex-col items-center p-6 bg-white dark-mode:bg-gray-800 shadow-lg rounded-lg">
            <div className="text-orange-500 text-5xl mb-4">
              <i className="fas fa-briefcase"></i>
            </div>
            <h3 className="text-xl font-semibold mb-2 text-gray-900 dark-mode:text-gray-100">Access to latest job opening</h3>
            <p className="text-gray-700 dark-mode:text-gray-300">
              Explore the latest job listings from top companies. We provide information about the latest job opportunities from different companies, bringing you the best opportunities to advance your career.
            </p>
          </div>
          <div className="flex flex-col items-center p-6 bg-white dark-mode:bg-gray-800 shadow-lg rounded-lg">
            <div className="text-orange-500 text-5xl mb-4">
              <i className="fas fa-users"></i>
            </div>
            <h3 className="text-xl font-semibold mb-2 text-gray-900 dark-mode:text-gray-100">Online community</h3>
            <p className="text-gray-700 dark-mode:text-gray-300">
              Join our vibrant community of coders, where you can share ideas, collaborate on projects, and get advice from fellow enthusiasts and experts.
            </p>
          </div>
        </div>

        <div className="mt-16">
          <h2 className="text-4xl font-bold mb-8 text-gray-900 dark-mode:text-gray-100">Join us</h2>
          <p className="max-w-2xl mx-auto mb-8 text-gray-700 dark-mode:text-gray-300">
            Whether you're here to learn, connect, or find your next big opportunity, Coding Community is the place for you. We're more than just a platform—we're a community. Come, code, and create with us!
          </p>
          <div className="grid grid-cols-2 sm:grid-cols-4 gap-4 mb-8">
            <div className="bg-orange-500 h-52 md:h-72 rounded-lg"></div>
            <div className="bg-orange-500 h-52 md:h-72 rounded-lg"></div>
            <div className="bg-orange-500 h-52 md:h-72 rounded-lg"></div>
            <div className="bg-orange-500 h-52 md:h-72 rounded-lg"></div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhatWeOffer;
