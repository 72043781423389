// src/components/RotatingPlaceholderSearch.js
import React, { useState, useEffect } from "react";
import { motion } from 'framer-motion';

const RotatingPlaceholderSearch = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = ["All courses", "Web Development", "Data Science", "Cloud DevOps"];
  const period = 100;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text]);

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setDelta(150);
    }
  };

  return (
    <motion.input 
      type="text" 
      placeholder={text}
      transition={{ delay: 1.5, duration: 1 }}
      className="flex-grow border border-gray-400 p-2 rounded-lg"

    />
  );
};

export default RotatingPlaceholderSearch;
