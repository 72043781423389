// src/CourseCategory.js
import React from 'react';
import course from '../../assets/icon/course.svg';
import laptop from '../../assets/icon/laptop.svg';
import placement from '../../assets/icon/placement.svg';
import webdev from '../../assets/icon/webdev.svg';
import ml from '../../assets/icon/ml.svg';
import cloud from '../../assets/icon/cloud.svg';
import OnlineSkills from './OnlineSkills';
import RotatingPlaceholderSearch from './RotatingPlaceholderSearch';

const CourseCategory = () => {
  return (
    <>
      <div className="flex flex-col justify-center mx-auto min-h-screen lg:-mt-24 md:mt-0 p-6 mt-0">
        {/* First Row */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 ">
          {/* Text Section */}
          <div className="flex flex-col justify-center lg:ml-20">
            <h1 className="text-4xl font-bold mb-4">
              Browse through our <p className="text-orange-500">course category</p>
            </h1>
            <p className="text-lg mb-6">
              Start your tech journey, browse through our course <p>category.</p>
            </p>
            <div className="flex flex-col lg:flex-row">
  <div className="flex w-full lg:w-[550px] items-center">
    <RotatingPlaceholderSearch />
    <button className="ml-2 px-4 w-12 sm:w-14 py-2 bg-orange-500 text-white rounded-full">
      <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 10-14 0 7 7 0 0014 0z"></path>
      </svg>
    </button>
  </div>
</div>

          </div>
          {/* Image Section */}
          <div className="flex justify-center items-center">
  <img
    src="https://via.placeholder.com/500x300"
    alt="Category"
    className="h-48 sm:h-72 md:h-96 w-auto rounded-lg shadow-lg"
  />
</div>
        </div>
          {/* Second Row */}
      <div className="grid grid-cols-2 gap-4 p-4 mt-10  bg-white rounded-md sm:grid-cols-3 lg:grid-cols-6">
        <div className="p-2 bg-orange-800 text-white shadow-md rounded-md flex justify-between items-center h-16">
          <span className="truncate">Live Course</span>
          <img src={laptop} alt="Video Icon" className="h-8 w-8 text-white" style={{ color: 'white' }} />
        </div>
        <div className="p-2 bg-orange-500 text-white shadow-md rounded-md flex justify-between items-center h-16">
          <span className="truncate">Fundamental Course</span>
          <img src={course} alt="Book Icon" className="h-8 w-8 text-white" />
        </div>
        <div className="p-2 bg-orange-400 text-white shadow-md rounded-md flex justify-between items-center h-16">
          <span className="truncate">DSA/Placement</span>
          <img src={placement} alt="Briefcase Icon" className="h-8 w-8 text-white" />
        </div>
        <div className="p-2 bg-orange-500 text-white shadow-md rounded-md flex justify-between items-center h-16">
          <span className="truncate">Web development</span>
          <img src={webdev} alt="Video Icon" className="h-8 w-8 text-white" />
        </div>
        <div className="p-2 bg-orange-700 text-white shadow-md rounded-md flex justify-between items-center h-16">
          <span className="truncate">Machine Learning</span>
          <img src={ml} alt="Book Icon" className="h-8 w-8 text-white" />
        </div>
        <div className="p-2 bg-orange-800 text-white shadow-md rounded-md flex justify-between items-center h-16">
          <span className="truncate">Data Science</span>
          <img src={cloud} alt="Briefcase Icon" className="h-8 w-8 text-white" />
        </div>
      </div>
      </div>
      <OnlineSkills />
    
    </>
  );
};

export default CourseCategory;
