import React from 'react';

const HomeFooter = () => {
  return (
    <footer className="bg-black text-white">
      <div className="p-8">
        <div className="flex flex-wrap justify-between">
          <div className="w-full md:w-1/4 mb-8 md:mb-0">
            <address className="not-italic mr-8 mb-5">
              A-143, 9th Floor, Sovereign Corporate Tower, Sector-136, Noida, Uttar Pradesh - 201305
            </address>
            <div className="flex space-x-2 flex-wrap mr-10 justify-center ">
              <img src="https://placehold.co/50x50" alt="Company Logo 1" className="w-10 mb-4 h-10" />
              <img src="https://placehold.co/50x50" alt="Company Logo 2" className="w-10 mb-4 h-10" />
              <img src="https://placehold.co/50x50" alt="Company Logo 3" className="w-10 mb-4 h-10" />
              <img src="https://placehold.co/50x50" alt="Company Logo 4" className="w-10 mb-4 h-10" />
              <img src="https://placehold.co/50x50" alt="Company Logo 5" className="w-10 mb-4 h-10" />
            </div>
          </div>
          <div className="w-full md:w-3/4">
            <div className="grid grid-cols-2 md:grid-cols-5 gap-8">
              <div>
                <h3 className="font-semibold mb-2">Company</h3>
                <ul className="space-y-1">
                  <li><a href="#" className="hover:text-primary">About Us</a></li>
                  <li><a href="#" className="hover:text-primary">Legal</a></li>
                  <li><a href="#" className="hover:text-primary">Careers</a></li>
                  <li><a href="#" className="hover:text-primary">In Media</a></li>
                  <li><a href="#" className="hover:text-primary">Contact Us</a></li>
                  <li><a href="#" className="hover:text-primary">Advertise with us</a></li>
                  <li><a href="#" className="hover:text-primary">GFG Weekly Contest</a></li>
                  <li><a href="#" className="hover:text-primary">Placement Training Program</a></li>
                </ul>
              </div>
              <div>
                <h3 className="font-semibold mb-2">Languages</h3>
                <ul className="space-y-1">
                  <li><a href="#" className="hover:text-primary">Python</a></li>
                  <li><a href="#" className="hover:text-primary">Java</a></li>
                  <li><a href="#" className="hover:text-primary">C++</a></li>
                  <li><a href="#" className="hover:text-primary">PHP</a></li>
                  <li><a href="#" className="hover:text-primary">GoLang</a></li>
                  <li><a href="#" className="hover:text-primary">SQL</a></li>
                  <li><a href="#" className="hover:text-primary">R Language</a></li>
                  <li><a href="#" className="hover:text-primary">Android Tutorial</a></li>
                </ul>
              </div>
              <div>
                <h3 className="font-semibold mb-2">DSA</h3>
                <ul className="space-y-1">
                  <li><a href="#" className="hover:text-primary">Data Structures</a></li>
                  <li><a href="#" className="hover:text-primary">Algorithms</a></li>
                  <li><a href="#" className="hover:text-primary">DSA for Beginners</a></li>
                  <li><a href="#" className="hover:text-primary">Basic DSA Problems</a></li>
                  <li><a href="#" className="hover:text-primary">DSA Roadmap</a></li>
                  <li><a href="#" className="hover:text-primary">DSA Interview Questions</a></li>
                  <li><a href="#" className="hover:text-primary">Competitive Programming</a></li>
                </ul>
              </div>
              <div>
                <h3 className="font-semibold mb-2">Data Science & ML</h3>
                <ul className="space-y-1">
                  <li><a href="#" className="hover:text-primary">Data Science with Python</a></li>
                  <li><a href="#" className="hover:text-primary">Data Science for Beginners</a></li>
                  <li><a href="#" className="hover:text-primary">Machine Learning Tutorial</a></li>
                  <li><a href="#" className="hover:text-primary">ML Maths</a></li>
                  <li><a href="#" className="hover:text-primary">Data Visualization</a></li>
                  <li><a href="#" className="hover:text-primary">Pandas Tutorial</a></li>
                </ul>
              </div>
              <div>
                <h3 className="font-semibold mb-2">Web Technologies</h3>
                <ul className="space-y-1">
                  <li><a href="#" className="hover:text-primary">HTML</a></li>
                  <li><a href="#" className="hover:text-primary">CSS</a></li>
                  <li><a href="#" className="hover:text-primary">JavaScript</a></li>
                  <li><a href="#" className="hover:text-primary">TypeScript</a></li>
                  <li><a href="#" className="hover:text-primary">React JS</a></li>
                  <li><a href="#" className="hover:text-primary">Next JS</a></li>
                  <li><a href="#" className="hover:text-primary">Node JS</a></li>
                  <li><a href="#" className="hover:text-primary">Bootstrap</a></li>
                  <li><a href="#" className="hover:text-primary">Tailwind CSS</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center py-4">
        <p>Coding Community all rights reserved &copy;2024</p>
      </div>
      <button id="scrollToTopBtn" className="fixed bottom-4 right-4 p-2 bg-gray-800 rounded-full">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="white">
          <g>
            <path d="M12,2A10,10,0,1,0,22,12,10.011,10.011,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8.009,8.009,0,0,1,12,20Z"/>
            <polygon points="7.293 15.293 8.707 16.707 12 13.414 15.293 16.707 16.707 15.293 12 10.586 7.293 15.293"/>
            <polygon points="7.293 11.293 8.707 12.707 12 9.414 15.293 12.707 16.707 11.293 12 6.586 7.293 11.293"/>
          </g>
        </svg>
      </button>
    </footer>
  );
};

export default HomeFooter;
