import React, { useState, useEffect } from 'react';

const Modal = () => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    // Show the modal after 3 seconds
    const showTimer = setTimeout(() => {
      setShowModal(true);
    }, 3000); // 3000 milliseconds = 3 seconds

    // Close the modal automatically after 10 seconds
    const closeTimer = setTimeout(() => {
      setShowModal(false);
    }, 13000); // 13000 milliseconds = 13 seconds (3 seconds delay + 10 seconds visibility)

    // Cleanup timers on component unmount
    return () => {
      clearTimeout(showTimer);
      clearTimeout(closeTimer);
    };
  }, []);

  const handleClose = () => setShowModal(false);

  return (
    <>
      {showModal && (
        <>
          <div className="fixed inset-0 bg-black bg-opacity-50 z-40" onClick={handleClose}></div>
          <div className="fixed inset-0 flex items-center justify-center z-50 p-4">
            <div className="bg-gradient-to-r from-orange-400 to-orange-300 p-8 md:p-12 max-w-lg w-full h-auto relative rounded-lg">
              <button
                onClick={handleClose}
                className="absolute top-4 right-4 text-gray-200 hover:text-white text-2xl"
              >
                &times;
              </button>
              <h2 className="text-3xl md:text-4xl font-extrabold mb-6 text-white text-center">
                Subscribe And Get 20% OFF On Your First Online Course
              </h2>
              <div className="flex flex-col md:flex-row items-center justify-center mb-6 space-y-4 md:space-y-0 md:space-x-4 p-5">
                <input
                  type="email"
                  placeholder="Write Your Email Address*"
                  className="p-3 rounded-full w-full md:w-80 border border-gray-300 focus:outline-none"
                />
                <button className="bg-white text-orange-600 px-6 py-3 rounded-full font-bold hover:bg-orange-400 focus:outline-none">
                  Submit
                </button>
              </div>
              <p className="text-sm text-gray-200 text-center">
                Sign Up for our newsletter and never miss any offers
              </p>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Modal;
