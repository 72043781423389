import React from 'react';
import image from '../assets/code1.png';
import HomeHeader from '../components/HomeHeader.jsx'
import TrendingArticles from '../components/TrendingArticles.jsx';
import FacebookIcon from '../assets/icon/facebook.svg';
import LinkedinIcon from '../assets/icon/linkedin.svg';
import TwitterIcon from '../assets/icon/twitter.svg';
import GmailIcon from '../assets/icon/gmail.svg';

function NewsDetail() {
  return (
    <>
    <HomeHeader/>
    <div className="container mx-auto p-4">
      <div className="flex flex-col lg:flex-row">
        <MainContent/>
        <Sidebar/>
      </div>
    </div>
    </>
  );
}
const MainContent = () => (
  <div className="w-full lg:w-2/3 mt-32 lg:mt-72 ml-8  md:mt-32 sm:mt-32 lg:pr-4">
    <h1 className="text-2xl font-bold mb-4">AI, IoT, Quantum Security Among Top 10 Emerging Technologies: Forrester</h1>
    <div className="mb-4">
      <img src={image} alt="AI and Quantum Security Illustration" className="w-full rounded-md" />
    </div>
    <p className="mb-4">
      The next chapter in the era of emerging technologies will see Forrester highlight AI, IoT, and quantum security as the top trends to watch out for. These technologies are expected to
      revolutionize various industries, bringing about significant changes in how businesses operate and interact with their customers.
    </p>
    <p className="mb-4">Forrester's report identifies the following key technologies:</p>
    <ul className="list-disc list-inside mb-4">
      <li>Artificial Intelligence (AI)</li>
      <li>Internet of Things (IoT)</li>
      <li>Quantum Security</li>
    </ul>
    <p className="mb-4">
      These technologies are expected to drive innovation and create new opportunities for businesses across various sectors. Companies that invest in these technologies will be well-positioned to
      gain a competitive edge in the market.
    </p>
    <Section title="AI and IoT">
      AI and IoT are already making significant impacts in various industries. AI is being used to improve customer experiences, optimize operations, and drive innovation. IoT, on the other hand, is
      enabling businesses to collect and analyze data from connected devices, providing valuable insights that can be used to improve decision-making and drive growth.
    </Section>
    <Section title="Quantum Security">
      Quantum security is an emerging field that aims to protect data from being compromised by quantum computers. As quantum computing technology advances, traditional encryption methods may become
      vulnerable to attacks. Quantum security technologies are being developed to address this challenge and ensure the security of sensitive information.
    </Section>
    <div className="flex items-center space-x-2 mt-4">
      <span className="text-sm text-gray-600">Share:</span>
      <a href="#" className="text-orange-500 hover:underline">
        <img width="40" height="40" src={FacebookIcon} alt="facebook" />
      </a>
      <a href="#" className="text-orange-500 hover:underline">
        <img width="40" height="40" src={LinkedinIcon} alt="linkedin" />
      </a>
      <a href="#" className="text-orange-500 hover:underline">
        <img width="40" height="40" src={TwitterIcon} alt="twitter" />
      </a>
      <a href="#" className="text-orange-500 hover:underline">
        <img width="40" height="40" src={GmailIcon} alt="gmail" />
      </a>
    </div>

    <div className=" mx-auto bg-white shadow-md rounded-md p-4 mt-5">
      <h2 className="text-xl font-bold mb-2">Post a comment</h2>
      <div className="border-b-2 border-orange-500 w-40 mb-4"></div>
      <textarea
        className="w-full h-24 p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
        
        placeholder="Write a comment..."
      ></textarea>
      <button
        className="w-full bg-orange-500 text-white py-2 rounded-md mt-4 hover:bg-orange-600"
        
      >
        Post
      </button>
      <div className="text-right text-gray-500 mt-2"> Comments</div>
    </div>
  </div>
);

const Sidebar = () => (
  <div className="w-full lg:w-1/3 lg:pl-4 mt-8 ml-8 lg:mt-72">
        {/* <Card title="About Us">
      <p className="text-sm">TechNewsWorld is your source for the latest news and trends in technology. Stay informed with our in-depth articles and expert analysis.</p>
    </Card> */}

<TrendingArticles/>

<Card title="Category">
  <div className="grid grid-cols-4 gap-2">
    <div className="flex flex-col items-center ">
    <img   width="35" height="35" src="https://img.icons8.com/ios-filled/50/bot.png" alt="bot"/>      <Tag name="AI" />
    </div>
    <div className="flex flex-col items-center">
    <img width="35" height="35" src="https://img.icons8.com/external-outline-black-m-oki-orlando/32/external-iot-internet-of-things-outline-black-m-oki-orlando.png" alt="external-iot-internet-of-things-outline-black-m-oki-orlando"/>        <Tag name="IoT" />
    </div>
    <div className="flex flex-col items-center">
    <img width="35" height="35" src="https://img.icons8.com/ios-filled/50/security-shield-green.png" alt="security-shield-green"/>         <Tag name=" Security" />
    </div>
    <div className="flex flex-col items-center">
    <img width="35" height="35" src="https://img.icons8.com/windows/32/blockchain.png" alt="blockchain"/>      <Tag name="Blockchain" />
    </div>
    <div className="flex flex-col items-center">
    <img width="35" height="35" src="https://img.icons8.com/ios-filled/50/radio-tower.png" alt="radio-tower"/>      <Tag name="5G" />
    </div>
  </div>
</Card>



<Card title="Related Articles">
      <ul className="space-y-2">
        <li><a href="#" className="text-orange-500 hover:underline">Understanding AI and Machine Learning</a></li>
        <li><a href="#" className="text-orange-500 hover:underline">How IoT is Transforming Industries</a></li>
        <li><a href="#" className="text-orange-500 hover:underline">The Future of Quantum Computing</a></li>
        <li><a href="#" className="text-orange-500 hover:underline">Understanding AI and Machine Learning</a></li>
        <li><a href="#" className="text-orange-500 hover:underline">How IoT is Transforming Industries</a></li>
        <li><a href="#" className="text-orange-500 hover:underline">The Future of Quantum Computing</a></li>
      </ul>
    </Card>
    {/* <Card title="Subscribe to our Newsletter">
      <p className="text-sm mb-4">Stay updated with the latest news and trends in technology.</p>
      <input type="email" placeholder="Enter your email" className="w-full p-2 border rounded mb-2" />
      <button className="bg-orange-500 text-white w-full p-2 rounded">Subscribe</button>
    </Card> */}
  </div>
);

const Section = ({ title, children }) => (
  <div>
    <h2 className="text-xl font-semibold mb-2">{title}</h2>
    <p className="mb-4">{children}</p>
  </div>
);

const Card = ({ title, children }) => (
  <div className="bg-gray-100 p-4 rounded-md mb-4">
    <h3 className="text-lg font-semibold mb-2">{title}</h3>
    {children}
  </div>
);

const Tag = ({ name }) => (
  <a href="#" className=" text-gray-900 px-2 py-1 rounded text-[14px]">{name}</a>
);

export default NewsDetail;
