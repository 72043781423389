import React from 'react'
import Video from '../components/CourseDetail/Video'
import ReviewCard from '../components/CourseDetail/ReviewCard'

const CourseDetailScreen = () => {
  return (
    <>
    <Video/>
    </>
  )
}

export default CourseDetailScreen