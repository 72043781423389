import React from "react";

function ContactusPage() {
  return (
    <>
      <div className="flex flex-col items-center justify-center bg-gray-100">
        <h1 className="text-3xl font-bold text-black mb-4 mt-10">Contact us</h1>
        <p className="text-center text-gray-700 mb-10 max-w-xl">
          We’d love to hear from you! Whether you have a question, feedback, or
          just want to say hello, the Coding Community team is here to help.
        </p>
      </div>
      <div className="flex flex-col md:flex-row items-center justify-center bg-gray-100">
        <div className="bg-white shadow-lg rounded-lg p-8 flex flex-col md:flex-row max-w-6xl w-full">
          {/* Left Side */}
          <div className="w-full md:w-1/2 p-4">
            <h2 className="text-2xl font-bold text-black mb-4">Get in touch</h2>
            <p className="text-gray-700 mb-8">
              Have a question about our platform, services, or community,
              experiencing issues or need assistance? Our support team is ready
              to assist you.
            </p>

            <div className="mb-6">
              <h3 className="text-lg font-semibold text-black">Address</h3>
              <p className="text-gray-700">
                MVPF+77W, Khandwa Rd, Indrapuri Colony, Bhanwar Kuwa, Indore,
                Madhya Pradesh 452001
              </p>
            </div>

            <div className="mb-6">
              <h3 className="text-lg font-semibold text-black">Contact number</h3>
              <p className="text-gray-700">78988230832</p>
            </div>

            <div className="mb-6">
              <h3 className="text-lg font-semibold text-black">Email address</h3>
              <p className="text-gray-700">codingcommunity.in@gmail.com</p>
            </div>

            <div className="flex space-x-4">
              <a href="#" className="text-orange-500 text-2xl">
                <i className="fab fa-instagram"></i>
              </a>
              <a href="#" className="text-orange-500 text-2xl">
                <i className="fab fa-telegram"></i>
              </a>
              <a href="#" className="text-orange-500 text-2xl">
                <i className="fab fa-youtube"></i>
              </a>
            </div>
          </div>

          {/* Right Side */}
          <div className="w-full md:w-1/2 p-4">
            <h2 className="text-2xl font-bold text-black mb-4">Send a message</h2>
            <p className="text-gray-700 mb-8">Welcome, enter your details</p>

            <form className="flex flex-col">
              <input
                type="text"
                placeholder="Name"
                className="w-full p-3 mb-4 border rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
              />
              <input
                type="email"
                placeholder="Email"
                className="w-full p-3 mb-4 border rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
              />
              <textarea
                placeholder="Write message"
                className="w-full p-3 mb-4 border rounded-lg h-32 focus:outline-none focus:ring-2 focus:ring-orange-500"
              />
              <button
                type="submit"
                className="w-full bg-orange-500 text-white p-3 rounded-lg hover:bg-orange-600"
              >
                Send Message
              </button>
            </form>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center py-10 bg-gray-100">
        <h2 className="text-3xl font-semibold text-gray-800 mb-4">Find us on Google maps</h2>
        <p className="text-gray-600 mb-8 text-center">
          We're easy to find! Check out the map below to see exactly where we are located.
        </p>
        <div className="w-full max-w-6xl">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.335258387427!2d-122.41941508468112!3d37.77492927975921!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8085818c1f5fddf5%3A0xd70f3173f86593d3!2sSan%20Francisco%2C%20CA%2C%20USA!5e0!3m2!1sen!2sin!4v1628529672347!5m2!1sen!2sin"
            width="100%"
            height="450"
            className="border-0"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="Google Map"
          ></iframe>
        </div>
      </div>
    </>
  );
}

export default ContactusPage;
