import React from 'react';
import ReactDOM from 'react-dom/client';
import { 
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider
} from 'react-router-dom';
import './styles/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import HomeScreen from './screens/HomeScreen';
import Jobapply from './screens/Jobapply';
import Newspage from './screens/Newspage';
import DetailPage from './screens/Detailpage';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Jobpage from './screens/Jobpage';
import NewsDetail from './screens/NewsDetail';
import CatelogPage from './screens/CatelogPage';
import CourseDetailScreen from './screens/CourseDetailScreen';
import DiscussionPage from './screens/DiscussionPage';
import QuestionDiscussion from './screens/QuestionDiscussion';
import ContactusPage from './screens/ContactusPage';
import NotFoundPage from './screens/NotFoundPage';  // Import the 404 component
import AboutPage from './screens/AboutPage';
import CommunityGroup from './screens/CommunityGroup';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<App />} >
      <Route index path='/' element={<HomeScreen />} />
      <Route path='/catelog' element={<CatelogPage />} />
      <Route path='/job' element={<Jobpage />} />
      <Route path='/jobapply' element={<Jobapply />} />
      <Route path='/news' element={<Newspage />} />
      <Route path='/detailpage' element={<DetailPage />} />
      <Route path='/coursedetailpage' element={<CourseDetailScreen />} />
      <Route path='/aboutus' element={< AboutPage/>} />
      <Route path='/community' element={< CommunityGroup/>} />
      <Route index ={true} path='/discussionpage' element={<DiscussionPage/>} />
      <Route index ={true} path='/questiondiscussion' element={<QuestionDiscussion/>} />
      <Route index ={true} path='/contact' element={<ContactusPage/>}/> 
      <Route path='*' element={<NotFoundPage />} />  {/* Catch-all route */}
    </Route>
  )
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();
