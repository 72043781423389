import React from 'react'
import HomeHeader from '../components/HomeHeader'

import Welcome from '../components/HomeComponents/Welcome'
import InfoBox from '../components/HomeComponents/InfoBox'

import CourseContainerthree from '../components/HomeComponents/CourseContainerthree'
import CoursesContainer from '../components/HomeComponents/CoursesContainer'
import CoursesContainerOne from '../components/HomeComponents/CourseContainerOne'
import HomeFooter from '../components/HomeComponents/HomeFooter'
import CoursesContainerFour from '../components/HomeComponents/CoursesContainerFour'
import SubscriptionBanner from '../components/HomeComponents/SubscriptionBanner'
const HomeScreen = () => {
  return (
    <>
    <HomeHeader/>
    {/* <SecondaryHeader/> */}
    <Welcome/>
    <InfoBox/>
    <CoursesContainer/>
    <CoursesContainerOne/>
    <CourseContainerthree/>
    <CoursesContainerFour/>
    <SubscriptionBanner/>
    <HomeFooter/>
 
    </>
  )
}

export default HomeScreen