import React from 'react';
import '../styles/App.css';
import ad from '../assets/ad.jpg'

const ImprovementModal = ({ onClose }) => {
  const handleContainerClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div className="modal-overlay show" onClick={onClose}>
      <div className="modal-container" onClick={handleContainerClick}>
        <div className="modal-header">
          <h2 className='text-xl font-bold'>Improvement</h2>
          <span id="closeModalBtn" className="close-button" onClick={onClose}>&times;</span>
        </div>
        <div className="modal-content">
          <p className="blue">Please go through our recently updated <a href="#">Improvement Guidelines</a> before submitting any improvements.</p>
          <div className="suggestions">
            <h3 className='text-2xl font-bold mt-4'  >Suggest Changes</h3>
            <div className="suggestion">
              <p>Help us improve. Share your suggestions to enhance the article. Contribute your expertise and make a difference in the GeeksforGeeks portal.</p>
              <img src={ad} alt="Suggest Icon" />
            </div>
            <h3 className='text-2xl font-bold' >Create Improvement</h3>
            <div className="suggestion">
              <p>Enhance the article with your expertise. Contribute to the GeeksforGeeks community and help create better learning resources for all.</p>
              <img src={ad} alt="Improvement Icon" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImprovementModal;
