// src/Reviews.js
import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const reviews = [
  {
    name: "Recco Gracia",
    job: "Web Developer",
    image: "https://via.placeholder.com/150",
    review: "Condimentum vel viverra morbi quisque lobortis eu leo. A nulla pulvinar at ut in sit libero, sed. Quis congue pretium in enim gravida quam netus in lorem. Nulla at nibh lorem nunc sapien egestas at cursus."
  },
  {
    name: "hehe Yeal",
    job: "Web Developer",
    image: "https://via.placeholder.com/150",
    review: "Condimentum vel viverra morbi quisque lobortis eu leo. A nulla pulvinar at ut in sit libero, sed. Quis congue pretium in enim gravida quam netus in lorem. Nulla at nibh lorem nunc sapien egestas at cursus."
  },
  {
    name: "Lilly Will",
    job: "Web Developer",
    image: "https://via.placeholder.com/150",
    review: "Condimentum vel viverra morbi quisque lobortis eu leo. A nulla pulvinar at ut in sit libero, sed. Quis congue pretium in enim gravida quam netus in lorem. Nulla at nibh lorem nunc sapien egestas at cursus."
  },
  {
    name: "John Doe",
    job: "Web Developer",
    image: "https://via.placeholder.com/150",
    review: "Condimentum vel viverra morbi quisque lobortis eu leo. A nulla pulvinar at ut in sit libero, sed. Quis congue pretium in enim gravida quam netus in lorem. Nulla at nibh lorem nunc sapien egestas at cursus."
  },
  {
    name: "Jane Smith",
    job: "Web Developer",
    image: "https://via.placeholder.com/150",
    review: "Condimentum vel viverra morbi quisque lobortis eu leo. A nulla pulvinar at ut in sit libero, sed. Quis congue pretium in enim gravida quam netus in lorem. Nulla at nibh lorem nunc sapien egestas at cursus."
  },
  {
    name: "Jane Smith",
    job: "Web Developer",
    image: "https://via.placeholder.com/150",
    review: "Condimentum vel viverra morbi quisque lobortis eu leo. A nulla pulvinar at ut in sit libero, sed. Quis congue pretium in enim gravida quam netus in lorem. Nulla at nibh lorem nunc sapien egestas at cursus."
  },
];

const CourseReview = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      }
    ]
  };

  return (
    <div className="bg-orange-50 p-10 min-h-[500px] w-[80%] mx-auto flex justify-center items-center mt-10 mb-10 rounded-full shadow-md">
      <div className="w-full">
        <h2 className="text-center text-red-500 text-sm mb-6">What our students say about us</h2>
        <h1 className="text-center text-4xl font-bold mb-10">Reviews</h1>
        <Slider {...settings}>
          {reviews.map((review, index) => (
            <div key={index} className="px-4 mb-10">
              <div className="text-center">
                <p className="mb-4">{review.review}</p>
                <div className="flex justify-center mb-2">
                  <img src={review.image} alt={review.name} className="w-12 h-12 rounded-full" />
                </div>
                <h3 className="font-bold text-xl">{review.name}</h3>
                <p className="text-gray-500">{review.job}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default CourseReview;
