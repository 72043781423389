import React from 'react';

const logos = [
  'https://via.placeholder.com/100x50?text=Logo1',
  'https://via.placeholder.com/100x50?text=Logo2',
  'https://via.placeholder.com/100x50?text=Logo3',
  'https://via.placeholder.com/100x50?text=Logo4',
  'https://via.placeholder.com/100x50?text=Logo5',
  // Add more URLs to dummy logos as needed
];

const LearnersAtWork = () => {
  return (
   <>
  <h1 className="font-bold text-2xl my-7 text-center sm:text-left">Our learners at work</h1>
<div className="relative overflow-hidden m-auto">
  <div className="marquee-wrapper flex items-center">
    <div className="marquee flex">
      {logos.concat(logos).concat(logos).map((logo, index) => (
        <img key={index} src={logo} alt={`Logo ${index + 1}`} className="w-24 h-auto mx-4" />
      ))}
    </div>
  </div>
  <style jsx>{`
    .marquee-wrapper {
      width: 100%;
      overflow: hidden;
    }

    .marquee {
      display: flex;
      animation: marquee 15s linear infinite;
    }

    @keyframes marquee {
      0% {
        transform: translateX(0%);
      }
      100% {
        transform: translateX(-100%);
      }
    }

    @media (min-width: 640px) {
      .marquee-wrapper {
        width: 87%;
       margin:auto;
      }
    }
  `}</style>
</div>

   </>
  );
};

export default LearnersAtWork;













