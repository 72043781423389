import React from 'react'
import group5 from '../assets/Group 5.png'
import logo from '../assets/logo.png'
import '../styles/index.css'


const Jobapply = () => {
    return (
        <div>
            <div className="image-container">
                <img src={group5} alt="Background Image" />
            </div>
            <div className="container">
                <div className="column">
                    <div className="app-option">
                        <div className="headleft">
                            <header>
                                <img src={logo} alt="Tax2win Logo" className="logo" />
                                <h1 className='text-4xl font-bold mb-3 '>Full Stack Developer</h1>
                                <p className="company mb-5">Tax2win</p>
                                <div className="side ">
                                    <a href="https://tax2win.in/" className="company-link">https://tax2win.in/</a>
                                    <p className="posted-date">Posted 2024-06-20</p>
                                </div>
                            </header>
                        </div>
                        <div className="d-headright">
                            <section className="job-details">
                                <div className="box">
                                    <strong><i className="fas apply-icon fa-dollar-sign"></i></strong>
                                    <p> Upto 10L per annum</p>
                                </div>
                                <div className="box">
                                    <strong><i className="fas apply-icon fa-map-marker-alt"></i></strong>
                                    <p> Onsite - Jaipur (Rajasthan)</p>
                                </div>
                                <div className="box">
                                    <strong><i className="fas apply-icon fa-briefcase"></i></strong>
                                    <p> Full Time - Fresher</p>
                                </div>

                            </section>
                            <div className="j-btn">
                                <button className="apply-btn">Apply Now</button>
                                <p className="apply-date"><i className="fas fa-info-circle"></i> Last Date to Apply Sat Jul 20 2024</p>
                            </div>
                        </div>
                    </div>
                    <hr className="jobapply-hr " />
                    <div className="ad-section">
                        <div className="ad-left">
                            <h2 className='text-2xl font-bold mb-4'>Skills</h2>
                            <section className="skills">
                                <span className="skill">Node.js</span>
                                <span className="skill">Software Development</span>
                                <span className="skill">Mobile Applications</span>
                            </section>
                            <section className="job-description">
                                <h2>Job Description</h2>
                                <p>We are looking for a Full Stack Developer to produce scalable software solutions. You'll be
                                    part of a cross-functional team that's responsible for the full software development life
                                    cycle, from conception to deployment.</p>
                                <p>As a Full Stack Developer, you should be comfortable around both front-end and back-end
                                    coding languages, development frameworks and third-party libraries. You should also be a
                                    team player with a knack for visual design and utility.</p>
                            </section>
                            <section className="responsibilities">
                                <h3 className='text-2xl font-bold mb-4'>Responsibilities</h3>
                                <ul>
                                    <li>Design client-side and server-side architecture</li>
                                    <li>Build the front-end of applications through appealing visual design</li>
                                    <li>Develop and manage well-functioning databases and applications</li>
                                    <li>Write effective APIs</li>
                                    <li>Test software to ensure responsiveness and efficiency</li>
                                    <li>Troubleshoot, debug and upgrade software</li>
                                    <li>Create security and data protection settings</li>
                                    <li>Build features and applications with a mobile responsive design</li>
                                    <li>Write technical documentation</li>
                                    <li>Work with data scientists and analysts to improve software</li>
                                </ul>
                            </section>
                            <section className="responsibilities">
                                <h3 className='text-2xl font-bold mb-4'>Requirements and skills </h3>
                                <ul>
                                    <li>Design client-side and server-side architecture</li>
                                    <li>Build the front-end of applications through appealing visual design</li>
                                    <li>Develop and manage well-functioning databases and applications</li>
                                    <li>Write effective APIs</li>

                                </ul>
                            </section>
                            <hr className="below-line bg-slate-700 bg-opacity-100" />
                            <div className="about-company">
                                <h2 className='text-2xl font-bold mb-4 mt-4'>About Company</h2>
                                <p>Indian tax laws are a maze of deadlines, exemptions and penalties, making it difficult for the
                                    taxpayer to file their ITR. Tax2win is India's leading income tax return filing platform that
                                    gives a quick, easy, hands-on tax filing experience. We are proud to be called "India ka Tax Expert"
                                    as we have revolutionised the way people file their taxes. Our platform is authorised by the IT
                                    department and we have secured a place amongst the top 25 emerging startups in Asia.</p>
                                <p>At Tax2win, our aim is to take the hassle and stress out of tax for you and help you win your
                                    taxes. Every month we spend over 2,40,000 hours doing taxes. When you avail of our paid services, your
                                    tax refund is prepared, checked and lodged by an experienced tax agent. With years of experience in
                                    the tax industry, we help you claim all the applicable deductions and offsets to tailor-make your
                                    return to your individual circumstances.</p>
                                <p>Above all, Tax2win aims to build quality relationships with every taxpayer and welcome lakhs of
                                    loyal customers back every year.</p>
                            </div>
                        </div>
                        <div className="d-ad-right">
                            <div className="p-6 max-w-sm mx-auto bg-white rounded-xl shadow-md space-y-4">
                                <div>
                                    <h2 className="text-xl font-semibold">Latest Jobs</h2>
                                </div>
                                <div className="space-y-2">
                                    <div className="flex items-center space-x-4">
                                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2f/Google_2015_logo.svg/368px-Google_2015_logo.svg.png" alt="Google Logo" className="w-14 h-14" />
                                        <div>
                                            <p className="text-sm font-medium text-gray-900">Google Off Campus Hiring For Software Engineer University Graduate 2025</p>
                                            <p className="text-sm text-gray-500">Bangalore / Hyderabad / Gurgaon / Mumbai / Pune</p>
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="flex items-center space-x-4 space-y-4">
                                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2f/Google_2015_logo.svg/368px-Google_2015_logo.svg.png" alt="Google Logo" className="w-14 h-14" />
                                        <div>
                                            <p className="text-sm font-medium text-gray-900">Calix Off Campus Hiring Fresher Customer Success Intern</p>
                                            <p className="text-sm text-gray-500">Bangalore</p>
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="flex items-center space-x-4 space-y-4">
                                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2f/Google_2015_logo.svg/368px-Google_2015_logo.svg.png" alt="Google Logo" className="w-14 h-14" />
                                        <div>
                                            <p className="text-sm font-medium text-gray-900">Calix Off Campus Hiring Fresher Customer Success Intern</p>
                                            <p className="text-sm text-gray-500">Bangalore</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>    </div>
    )
}

export default Jobapply
